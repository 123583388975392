.survey-confirmation {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #f5f6fa;
   padding: 2rem;

   .confirmation-content {
      display: flex;
      width: 100%;
      max-width: 1000px;
      background: white;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      overflow: hidden;
      flex-direction: row;

      @media (max-width: 768px) {
         flex-direction: column;
      }
   }

   .left-part {
      flex: 1 1 30%;
      background-color: #eef3ff;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 2rem;

      .illustration {
         max-width: 100%;
         height: auto;
      }

      @media (max-width: 768px) {
         justify-content: center;
         padding: 1rem;
      }
   }

   .right-part {
      flex: 1 1 70%;
      background: white;
      padding: 2rem;
      text-align: left;

      h1 {
         color: #3445e5;
         margin-bottom: 1rem;
         font-weight: 600;
      }

      p {
         color: #555;
         margin-bottom: 1rem;

         a {
            color: #3445e5;
            text-decoration: none;
         }
      }

      @media (max-width: 768px) {
         padding: 1rem;
      }
   }
}
