.wrapper {
   max-width: 550px;

   .optionsList {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .option {
         display: flex;
         justify-content: space-between;
         border-radius: 5px;
         border: 1px solid var(--blue-purple-purple-2, #9baceb);
         background: #fff;
         padding: 8px 14px;

         .leftSide {
            display: flex;
            align-items: center;
            gap: 16px;

            p {
               color: var(--greys-mid-dark-gray, #7c7c80);
               font-family: Poppins;
               font-size: 14px;
               font-style: normal;
               font-weight: 500;
               line-height: normal;
               letter-spacing: 0.28px;
            }
         }
      }
   }

   :global {
      .submissionStyle {
         pointer-events: initial;
         cursor: default;
      }
   }
}
