.table-data {
   display: flex;
   flex-direction: column;
   gap: 26px;

   tr {
      td:nth-child(1) {
         border-bottom: 0;
      }
   }

   .table-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Poppins;

      .filter-section {
         display: flex;
         justify-content: flex-end;
         align-items: center;

         .ant-input-affix-wrapper {
            padding: 10px;
         }

         .show-filters {
            width: 300px;
            color: var(--blue-purple-purple-1, #5770cb);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            margin: 0;

            @media (max-width: 768px) {
               display: none;
            }
         }

         button {
            span:last-child {
               @media (max-width: 768px) {
                  display: none;
               }
            }
         }

         @media (max-width: 768px) {
            width: 100%;
            gap: 10px;
         }
      }

      .table-text {
         color: var(--blue-purple-purple-1, #5770cb);
         font-size: 22px;
         font-style: normal;
         font-weight: 600;
         line-height: 26px;

         @media (max-width: 576px) {
            display: none;
         }

         @media (max-width: 768px) {
            width: 150px;
         }
      }
   }

   .ant-table-thead {
      .ant-checkbox {
         border: 1px solid #5770cb;
      }
      .ant-checkbox-inner {
         background-color: transparent;
      }

      .ant-checkbox-indeterminate {
         .ant-checkbox-inner::after {
            background-color: #5770cb;
         }
      }

      th:nth-child(1) {
         .anticon {
            display: none;
         }
      }

      .ant-table-cell {
         color: #5770cb;
         font-size: 13px;
         font-style: normal;
         font-weight: 500;
         line-height: 24px;
         background: #d9e3ff;
         letter-spacing: -0.13px;
      }
   }

   .ant-table-thead ::before {
      display: none;
   }

   .ant-table-tbody {
      color: #797979;

      td {
         font-family: "Poppins";
         font-size: 12px;
         font-weight: 500;
      }
   }

   .dataContentMobile {
      margin-bottom: 10px;
      background-color: #5770cb;
      border-radius: 8px;

      .generalDataInfo {
         padding: 16px 14px;

         .status {
            margin-bottom: 20px;
         }

         .name {
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 15px;
            color: #fff;
            padding-bottom: 22px;
         }

         .locationData {
            display: flex;
            justify-content: space-between;

            .position {
               display: flex;
               flex-direction: column;
            }

            .position:nth-child(1) {
               width: 90px;
            }

            span {
               color: #fff;
               font-family: Poppins;
               font-size: 13px;
               font-style: normal;
               font-weight: 500;
               line-height: 15px;
               font-weight: 400;
               padding-bottom: 4px;
               /* 115.385% */
            }

            .deadline {
               font-size: 14px;
               font-weight: 500;
            }
         }
      }
   }

   .status,
   .collectionType {
      font-weight: 500;
      padding: 4px 12px;
      text-align: center;
      border-radius: 16px;
      font-size: 12px;
      line-height: 18px;
      margin: 0;
   }

   // .assigned {
   //    background: #fff9e5;
   //    color: #fb8800;
   //    border: 1px solid #fff9e5;
   //    width: 100px;
   // }

   .finished {
      background: #e2ffef;
      color: #0e9349;
      border: 1px solid #e2ffef;
      width: 100px;
   }

   .assigned {
      background: #eef2ff;
      color: #6b89fc;
      border: 1px solid #eef2ff;
      width: 100px;
   }

   .rejected {
      background: #fce4e4;
      color: #ea5455;
      border: 1px solid #fce4e4;
      width: 100px;
   }

   // .not-started {
   //    background: #f2f2f2;
   //    color: #707073;
   //    border: 1px solid #f2f2f2;
   // }

   .collectionType {
      background: #eef2ff;
      color: #6b89fc;
      border: 1px solid #6b89fc;
      border-radius: 30px;
      width: 100px;
   }

   .filter-button {
      border-radius: 8px;
      background: var(--blue-purple-purple-1, #5770cb);
      box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
      display: flex;
      height: 44px;
      padding: 4px 16px;
      justify-content: center;
      align-items: center;
      color: var(--character-primary-inverse, #fff);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
   }

   .tableButtons {
      display: flex;

      .acceptOrReject {
         display: flex;
         justify-content: center;
         align-items: center;
         height: 30px;
         width: 30px;
         color: #acacac;
         border-color: #acacac;
         border-radius: 8px;
         background-color: #f2f6f9;
         margin-right: 10px;
         font-size: 16px;

         @media (max-width: 767px) {
            height: 44px;
            padding: 4px 16px;
            border-radius: 8px;
            // border: 1px solid var(--Blue-purple-Purple-2, #9baceb);
            background: var(--Blue-purple-Purple-4, #eef2ff);
            border: 1px solid #5770cb;
            // background-color: #5770cb;
            width: auto;
            margin-right: 0;
         }

         svg {
            @media (max-width: 767px) {
               fill: #5770cb;
            }
         }
      }

      .markAsDone:hover {
         color: #0e9349;
         background-color: #28c76f17;
         border-color: #0e9349;
      }

      .reject:hover {
         color: #ea5455;
         background-color: #ea54551a;
         border-color: #ea5455;
      }

      .goToSurvey:hover {
         color: #5770cb;
         background-color: #eef2ff;
         border-color: #5770cb;
      }

      @media (max-width: 767px) {
         display: flex;
         padding: 8px 14px;
         justify-content: flex-end;
         align-items: center;
         background: var(--Blue-purple-Purple-3, #d9e3ff);
         border-radius: 0 0 8px 8px;
         gap: 8px;
      }
   }
}

.tasksManagementPage {
   .searchTasks {
      padding: 20px 16px;
      margin-bottom: 40px;
      border-radius: 8px;
      border: 1.5px solid var(--Primary-Main-Blue-300, #cad6ff);
      background: var(--Primary-Main-Blue-100, #f9faff);
      width: 100%;

      .showFilters {
         display: flex;
         justify-content: flex-end;
         align-items: center;
         padding-right: 20px;
         cursor: pointer;
         span {
            color: var(--Primary-Main-Blue, #4158cf);
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
         }
      }
   }
}

.filter-modal {
   display: flex;
   padding: 40px;
   flex-direction: column;
   align-items: flex-end;
   gap: 32px;

   .ant-modal-content {
      width: 100%;
      padding: 40px !important;
   }

   .custom-modal-title {
      color: var(--primary-main-blue, #4158cf);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 144%;
   }

   .modal-buttons {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      gap: 16px;

      button {
         border-radius: 8px;
      }
   }

   .modal-buttons button:nth-child(1) {
      background-color: transparent;
      color: #000;
      border: none;
      color: var(--blue-purple-purple-1, #5770cb);
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      /* 157.143% */
   }

   .modal-buttons button:nth-child(2) {
      background: var(--blue-purple-purple-1, #5770cb);
      color: #fff;
      box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
   }
}

.filter-button:hover {
   color: #fff !important;
}
