.coming-soon{
    width: 100%;
    height: 100%;
    background-color: #252938;
    .kapsurvey-logo{
        display: none;
        padding: 20px;
        img{
            width: 150px;
            height: 90px;
        }
   
        }
        .comingSoon-illustration{
            display: flex !important;
            justify-content: center !important;
            align-items: center;
            flex-direction: column;
            margin: 2% auto;
            text-align: center;
            img{
                max-width: 500px;
                width: 100%;
            }
            h1{
                margin-bottom: 30px;
                font-size: 41px;
                color: #F58F44;
            }
            p{
                color: #FFFFFF;
                font-size: 18px;
                margin-top: 20px;
            }
           
    }
}
