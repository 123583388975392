.workspace-choose-page {
   font-family: Poppins, Helvetica, "sans-serif";

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   padding: 0;
   margin: 0;
   background-color: #eef2ff;

   h3 {
      font-size: 28px;
      font-weight: 600;
      color: #707073;
      margin-top: 5%;
   }

   h4 {
      font-size: 22px;
      font-weight: 600;
      color: #de8344;
   }

   .workspace-section {
      margin-bottom: 5%;
      margin-top: 1%;
      width: 80%;
      min-height: 50%;
      text-align: center;
      padding: 2% 5%;
      background-color: white;
      box-shadow: 0px 2px 8px 0px #e8e8e8;
      border-radius: 8px;

      .workspaces {
         position: relative;
         display: flex;
         justify-content: center;
         flex-wrap: wrap;
         gap: 20px;
         margin-top: 3%;

         .anticon {
            display: none;
         }

         .your-workspaces {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            color: white;
            background-color: #252938;
            height: 200px;
            width: 200px !important;
            border-radius: 18px;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: wrap;
            word-wrap: break-word;

            div {
               span {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  width: 60px;
                  height: 60px !important;
                  border-radius: 50%;
                  background-color: white;

                  img {
                     margin-top: 5px;
                  }
               }

               p {
                  padding-top: 23px;
                  overflow: hidden;
                  font-size: 16px;
                  color: white;
                  font-weight: 500;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: wrap;
                  word-wrap: break-word;
               }
            }
         }

         .add-new-workspace {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            background-color: #e7e7e7;
            height: 200px;
            width: 200px;
            border-radius: 18px;
            position: relative;

            .new-workspace-addIcon {
               cursor: pointer;
            }

            div {
               span {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  width: 60px;
                  height: 60px !important;
                  border-radius: 50%;
                  background-color: white;
                  cursor: pointer;

                  img {
                     width: 40px;
                     height: 40px;
                  }
               }

               p {
                  margin-top: 3px;
                  font-size: 16px;
                  color: #707073;
                  font-weight: 500;
               }
            }
         }
      }
   }
}

.work-box {
   position: relative;

   .ant-dropdown-trigger {
      opacity: 0;
      color: white;
      position: absolute !important;
      top: 0px !important;
      right: 10px;
      letter-spacing: 3px;
      font-size: 20px;
      z-index: 99;
      transition: opacity 0.3s ease-in-out;
   }

   &:hover {
      .ant-dropdown-trigger {
         opacity: 1;
      }
   }
}

.my-new-workspace {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   background-color: #eef2ff;
   height: 100vh;
   width: 100%;
   margin: 0 auto !important;

   .new-workspace-form {
      margin-bottom: 5%;
      width: 70%;
      min-height: 50%;
      padding: 4% 6%;
      background-color: white;
      box-shadow: 0px 2px 8px 0px #e8e8e8;
      border-radius: 8px;

      .ant-row {
         display: block !important;
      }

      .work-space {
         display: flex;
         flex-direction: column;
         align-items: center;
         width: 100%;

         h3 {
            color: #de8344;
            font-weight: 600;
            margin-bottom: 20px;
            font-size: 24px;
         }

         .workspace-button-group {
            button {
               width: 140px;
               height: 40px;
               transition: all 0.5s ease;
               font-weight: 600;
               letter-spacing: 1px;
            }

            .workspace-cancel-button {
               background-color: transparent;
               color: #707073;
               border-color: #707073 !important;

               a {
                  color: #707073;
               }

               &:hover {
                  background-color: #f0f0f0;

                  border-color: #252938 !important;
               }
            }

            .workspace-submit-button {
               background-color: #5770cb;
               color: white;

               &:hover {
                  background-color: #5770cb;
               }
            }
         }
      }
   }

   @media (max-width: 567px) {
      .new-workspace-form {
         width: 100% !important;
         margin-top: 8%;
      }
   }

   .ant-form-horizontal {
      width: 80% !important;
   }

   input {
      width: 100% !important;
   }

   textarea {
      width: 100% !important;
      border: 1px solid #d9d9d9;
      outline: none;
      border-radius: 8px;
   }

   label {
      color: #707073 !important;
      font-weight: 600;
   }
}

@media (max-width: 568px) {
   .my-new-workspace {
      align-items: flex-start !important;
   }
}

.ant-form-item-label > label::after {
   display: none !important;
}

.ant-modal-content {
   position: relative;
}

.remove-modal {
   span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      background-color: #8153f3;
      color: white;
      width: 60px;
      height: 60px;
      border-radius: 50%;
   }

   ::after {
      content: "";
      width: 100%;
      height: 10px;
      border-radius: 0 0 8px 8px;
      background-color: #8153f3;
      position: absolute;

      top: -0px;
      left: 0;
   }
}

.delete-modal-button-group {
   display: flex;
   justify-content: center;
   gap: 15px;
   color: var(--Greys-Dark-gray, #707073);
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 600;
   line-height: 24px;
   /* 171.429% */
   letter-spacing: -0.14px;

   button {
      border: none;
      padding: 8px 15px;
      border-radius: 8px;
   }

   .delete-modal-cancel {
      border: 1px solid #d9d9d9;
      background-color: transparent;

      &:hover {
         background-color: #d3d9d9;
      }
   }

   .yes-i-want-this {
      background-color: #8153f3;
      color: white;
   }
}

.workspace-members {
   padding: 3% 8%;
   margin: 2%;
   background-color: white;
   box-shadow: 0px 2px 8px 0px #e8e8e8;
   border-radius: 8px;

   .ant-pagination {
      justify-content: center !important;
   }

   // Workspace Members table
   tr {
      th {
         width: 25%;
         background-color: #d6deff !important;
         color: "#576596";
      }
   }

   .ant-table-tbody {
      tr {
         :nth-child(3) {
            text-align: end;
            padding-right: 15%;
         }

         .ant-table-cell {
         }
      }
   }

   .ant-table-thead > tr > td {
      background-color: #d6deff !important;
   }

   h3 {
      color: black;
      margin-bottom: 3%;

      span {
         display: inline-flex;
         align-items: center;
         color: #a6a6a6;
         margin-left: 10px;
         gap: 4px;
      }
   }

   .ant-select-selector {
      width: 130px !important;
   }

   .ant-select-selection-search {
      width: 140px !important;
   }

   .table-control-group {
      .table-control {
         gap: 15px;

         .ant-select-selector {
            display: flex;
            align-items: center;
            height: 40px !important;
         }

         button {
            border: none;
            height: 40px;
            width: 90px;
            background: #576596;
            color: white;
            border-radius: 50px;
            padding: 5px;
            transition: all 0.3s ease-in-out;

            &:hover {
               background-color: #394261;
            }
         }

         .filter-search-group {
            display: flex;
            gap: 15px;
         }
      }

      .new-member-button {
         border: none;
         height: 40px;
         width: 140px;
         background: #576596;
         color: white;
         border-radius: 8px;
         padding: 5px;
         transition: all 0.3s ease-in-out;

         margin-left: 10px;

         &:hover {
            background-color: #394261;
         }
      }
   }

   @media (max-width: 700px) {
      .table-control-group {
         flex-direction: column-reverse;

         .new-member-button {
            margin-bottom: 10px;
            margin-left: 0 !important;
            width: 100% !important;
         }
      }
   }

   @media (max-width: 499px) {
      .table-control {
         flex-direction: column;
      }
   }
}

.new-member-page {
   padding: 3% 8%;
   margin: 2% auto;
   background-color: white;
   box-shadow: 0px 2px 8px 0px #e8e8e8;
   border-radius: 8px;
   width: 80%;

   .new-member-information {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
   }

   button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      height: 40px;
      min-width: 140px;
      background: #576596;
      color: white;
      border-radius: 8px;
      padding: 5px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      margin-left: 10px;

      &:hover {
         background-color: #394261;
         color: white !important;
      }
   }

   .new-member-submit-button-group {
      display: flex;
      justify-content: center;
      margin: 25px 0 10px 0;

      .cancel {
         border: 1px solid #d9d9d9;
         background-color: transparent;
         color: #707073 !important;

         &:hover {
            border-color: #252938 !important;
         }
      }
   }

   .ant-select-selection-item {
      .tooltips {
         display: none !important;
      }
   }

   label {
      font-weight: 600;
   }
}

@media (max-width: 638px) {
   .new-member-page {
      margin-top: 10px;
      width: 100%;

      button {
         min-width: 110px;
      }

      .new-member-submit-button-group {
         display: flex;
         justify-content: center;
         margin: 25px 0 10px 0;
      }
   }
}

@media (max-width: 368px) {
   .new-member-page {
      button {
         min-width: 90px;
      }

      .new-member-submit-button-group {
         justify-content: flex-start;

         .ant-col {
            margin-left: 20px;
         }
      }
   }
}

@media (max-width: 368px) {
   .new-member-page {
      .new-member-submit-button-group {
         justify-content: flex-start;

         .ant-col {
            margin-left: 20px;
         }
      }
   }
}

.new-member-page-edit-modal {
   h2 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 8%;
   }

   label {
      font-weight: 600;
   }

   button {
      width: 120px;
   }

   .edit-modal-cancel {
      background-color: transparent;
      color: #707073;
      border: 1px solid #70707346;
      transition: all 0.3s ease-in-out;

      a {
         color: #707073;
      }

      &:hover {
         border-color: #252938 !important;
      }
   }

   .edit-modal-submit {
      border: none;
      height: 40px;
      background: #576596;
      color: white;
      border-radius: 8px;
      padding: 5px;
      transition: all 0.3s ease-in-out;

      margin-left: 10px;

      &:hover {
         background-color: #394261;
      }
   }
}

.tooltips {
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
   color: #8153f3 !important;
   border: 2px solid #8153f3 !important;
   border-radius: 50% !important;
   width: 17px !important;
   height: 17px !important;
}

.role-page-section {
   .role-page-form {
      background-color: white;
      padding: 2% 10% 8% 10%;
      margin: 3% auto;
      border-radius: 16px;
      box-shadow: rgba(25, 72, 35, 0.16) 0px 1px 4px;
      font-weight: 600;

      h2 {
         display: inline-flex !important;
         justify-content: center !important;
         width: 100%;
         font-weight: 700;
         font-size: 28px;
         color: #f77d48;
         margin-bottom: 30px;
      }

      .ant-row {
         display: block;
      }

      button {
         width: 140px;
         height: 40px;
         transition: all 0.5s ease;
         font-weight: 600;
         letter-spacing: 1px;
         margin-top: 30px;
      }

      @media (max-width: 350px) {
         button {
            width: 120px;
            height: 37px;
         }
      }

      .role-page-cancel-button {
         background-color: transparent;
         color: #707073;
         border-color: #707073 !important;
         margin-right: 10px;

         a {
            color: #707073;
         }

         &:hover {
            background-color: #f0f0f0;
            color: #707073;

            border-color: #252938 !important;
         }
      }

      .role-page-submit-button {
         background-color: #576596;
         color: white;

         &:hover {
            background-color: #394261;
         }
      }
   }
}

.kapSurveyPrimaryButton {
   display: inline-flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   flex-shrink: 0;
   border-radius: 8px;
   background: var(
      --Blue-purple-Purple-1,
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
      #5770cb
   ) !important;
   box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1) !important;
   color: white !important;
   font-weight: 500;
}

.kapSurveyPrimaryButton:hover:not(:disabled) {
   background: var(--Blue-purple-Purple-2, #9baceb !important);
   box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
   color: var(--Greys-White, #fff);
   border: none !important;
}

.kapSurveyPrimaryButton:disabled {
   display: inline-flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   flex-shrink: 0;
   border-radius: 8px;
   background: var(--Greys-Light-Gray-1, #f2f2f2);
   color: var(--Greys-Medium-Gray-3, #bfbfbf);
   cursor: not-allowed;
}

.kapSurveyPrimaryButtonFilter {
   display: flex;
   height: 41px;
   padding: 12px 18px;
   justify-content: center;
   align-items: center;
   gap: 10px;
   flex-shrink: 0;
   border-radius: 8px;
   background: var(
      --Blue-purple-Purple-1,
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
      #5770cb
   ) !important;
   box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1) !important;
   color: white !important;
   font-weight: 500;
}

.kapSurveyPrimaryButtonFilter:hover:not(:disabled) {
   background: var(--Blue-purple-Purple-2, #9baceb !important);
   box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
   color: var(--Greys-White, #fff);
   border: none !important;
}

.kapSurveyPrimaryButtonFilter:disabled {
   display: inline-flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   flex-shrink: 0;
   border-radius: 8px;
   background: var(--Greys-Light-Gray-1, #f2f2f2);
   color: var(--Greys-Medium-Gray-3, #bfbfbf);
   cursor: not-allowed;
}

.kapSurveySecondaryButton {
   display: inline-flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   flex-shrink: 0;
   border-radius: 8px;
   background: none !important;
   border: 1px solid var(--Blue-purple-Purple-1, #5770cb) !important;
   box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1) !important;
   color: var(--Blue-purple-Purple-1, #5770cb) !important;
   font-weight: 500;
}

.kapSurveySecondaryButton:hover:not(:disabled) {
   border-radius: 8px;
   border: 1px solid var(--Blue-purple-Purple-1, #5770cb) !important;
   background: rgba(87, 112, 203, 0.08);
   box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
   color: var(--Blue-purple-Purple-1, #5770cb) !important;
}

.kapSurveySecondaryButton:disabled {
   display: inline-flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   flex-shrink: 0;
   border-radius: 8px;
   background: var(--Greys-Light-Gray-1, #f2f2f2);
   color: var(--Greys-Medium-Gray-3, #bfbfbf);
   cursor: not-allowed;
}

.table-view-rating {
   width: 100%; /* Adjust width as needed */
}

.table-view-rating tr {
   width: 100% !important;
   min-height: 300px !important;
}

.table-view-rating th,
.table-view-rating td {
   border: 1px solid #00000050 !important; /* Apply border to cells */
   padding: 0 4px !important; /* Add padding to cells for better spacing */
   text-align: left; /* Adjust text alignment for headers */
}
