.filters {
   margin-top: 30px;
   width: 100%;

   .ant-select {
      width: 100%;
   }

   &--label-text {
      font-weight: 500;
      color: #3a3737;
   }

   &--filters-select {
      width: 100%;
   }
}
