.phone_number-submission-type-wrapper {
   &--input {
      max-width: 420px;
      width: 100%;

      .form-control {
         width: calc(100% - 70px) !important;
         height: 40px;
         padding: 9px 10px 9px 14px;
         margin-left: 70px;
         border-radius: 5px;
         border: 1px solid #999999;

         &:focus {
            border-color: #1677ff;
            box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
         }
      }
      .flag-dropdown {
         padding: 0 12px;
         background-color: white;
         border-radius: 5px;
         border: 1px solid #999999;

         &.open {
            border-radius: 5px;
            border-color: #1677ff;
            box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
         }
      }

      &.error {
         .form-control {
            border-color: #ff4d4f;
         }
         .flag-dropdown {
            border-color: #ff4d4f;
         }
      }
   }
}
