.preview-item-selector {
   &--repeat {
      padding: 10px;
      position: relative;

      .repeat_counter {
         position: absolute;
         top: 5px;
         right: 10px;
      }
   }
}
