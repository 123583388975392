.matrix-submission-type {
   &--wrapper {
      overflow: auto;

      &-table {
         width: 100%;
         min-width: 100%;

         & thead {
            & th {
               padding: 12px;
               background-color: #eef2ff;
               font-family: Poppins;
               font-size: 14px;
               font-weight: 500;
               line-height: 20.16px;
               letter-spacing: 0.01em;
               color: #5770cb;
               border-bottom: 1px solid #9baceb;
               border-left: 1px solid #9baceb;
               border-right: 1px solid #9baceb;
            }

            & th:first-child {
               border-top-left-radius: 5px;
               border-left: none;
            }
            & th:last-child {
               border-top-right-radius: 5px;
               border-right: none;
            }
         }

         & tbody {
            border: 1px solid transparent;

            &.error {
               border: 1px solid #ff4d4f;
            }

            & tr {
               border-bottom: 1px solid #d9d9d9;

               &.error {
                  border-bottom: 1px solid #ff4d4f;
               }
            }
            & td {
               font-family: Poppins;
               font-size: 14px;
               font-weight: 500;
               line-height: 20.16px;
               letter-spacing: 0.01em;
               color: #252938;
               padding: 8px 12px;
               vertical-align: top;
               border-left: 1px solid #d9d9d9;
               border-right: 1px solid #d9d9d9;

               &.error {
                  border-color: #ff4d4f;
               }

               &:first-child {
                  border-left: none;
               }
               &:last-child {
                  border-right: none;
               }
            }
         }

         &_textarea {
            border: none;
            border-radius: 2px;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 20.16px;
            letter-spacing: 0.01em;
            color: #7c7c80;
         }
      }
   }

   &--error {
      display: block;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      color: #ff4d4f;
   }
}
