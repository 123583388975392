.deleted-data-table {
   border: 1px solid #cad6ff;
   &-thead {
      .deleted-data-table-cell {
         background-color: #eef2ff !important;
         color: #5770cb !important;
      }
   }
   &-tbody {
      .deleted-data-table-cell {
         border-color: #cad6ff !important;
         color: #404040 !important;
      }
   }
}
