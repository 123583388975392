.cropper-crop-box,
.cropper-view-box {
   border-radius: 50%;
}

.cropper-view-box {
   box-shadow: 0 0 0 1px #39f;
   outline: 0;
}

.file-upload-page {
   .file-upload--title {
      color: #4158cf;
      text-align: center;
      font-size: 22px;
      font-weight: 600;
   }

   .file-upload-page--paragraph {
      color: #000000d9;
      font-size: 14px;
      margin-top: 30px;
      text-align: center;
   }

   .upload-file {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      border: 1px dashed #b3bcec;
      background: #f1f6ff;
      height: 200px;

      .upload-file--text {
         color: #4158cf;
         font-family: Poppins;
         font-size: 16px;
         font-style: normal;
         font-weight: 600;
         line-height: 24px;
         text-decoration-line: underline;
      }

      .icon-box {
         span {
            padding: 10px;
            background: white;
            border-radius: 50%;
         }
      }
   }
}
