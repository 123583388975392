.forgorPasswordModal {
   width: 500px;
   text-align: center;

   .borderTop {
      position: absolute;
      top: 0;
      left: 0;
      border: 5px solid #f58f45;
      width: 100%;
      border-radius: 30px;
   }

   img {
      margin: 10px 0 30px 0;
   }

   .title {
      color: var(--Orange-Orange, #f58f45);
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 20px;
   }

   .title2 {
      color: #465aa2;
      font-family: Poppins;
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
         text-align: center;
         max-width: 300px;
         margin: 0 auto;
      }
   }

   .description {
      color: var(--Greys-Dark-gray, #707073);
      text-align: center;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      padding: 0 !important;
   }

   .verificationText {
      color: #252938;
      font-weight: 600;
   }

   button {
      display: flex;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      background: var(
         --Blue-purple-Purple-1,
         linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
         #5770cb
      );
      box-shadow: 0px 3px 5px 0px rgba(128, 128, 128, 0.35);
      color: #fff;
      margin: 20px auto 0 auto;
      height: 38px;
   }

   .ant-modal-body {
      width: 330px;
      margin: 0 auto;

      @media only screen and (max-width: 600px) {
         width: auto;
      }
   }

   .ant-modal-close {
      border: none;
      box-shadow: none;
      color: #4158cf;

      .anticon-close {
         border: none !important;
      }
   }

   .ant-modal-close:hover {
      background: none;
   }
}
