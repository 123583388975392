.section{
   :global{
    .ant-input{
        height: 150px !important;
        // border-color: #9BACEB;
        &:focus{
            box-shadow: none;
        }
    }
   }
}
