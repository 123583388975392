.profile-subheader-link {
   color: red;
   padding: 16px;
}

.profile-subheader-link:hover {
   color: red;
   padding: 16px;
}

.backIcon {
   all: unset;
   align-items: center;
   cursor: pointer;
}

.backIcon h1 {
   margin-left: 16px;
   margin-bottom: 0 !important;
   color: var(--greys-mid-gray, #999);
   font-family: Poppins;
   font-size: 16px;
   font-style: normal;
   font-weight: 600;
   line-height: 24px;
   /* 150% */
}


.simple-menu-material-ui {
   ul {
      overflow: hidden;
   }
}