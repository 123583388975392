.firstRowWrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   align-self: stretch;

   .searchInput {
      border: none;
      display: flex;
      width: 40%;
      height: 40px;
      padding: 14px 0px 14px 14px;
      align-items: center;
      flex-shrink: 0;
      border-radius: 8px;
      background: var(--grey-grey-50, #f2f2f2);

      input {
         border-radius: 21px;
         background-color: var(--grey-grey-50, #f2f2f2);
         color: var(--greys-dark-gray, #707073);
         font-family: Poppins;
         font-size: 14px;
         font-style: normal;
         font-weight: 500;
         line-height: normal;
      }
   }

   .newMemberButton {
      display: flex;
      align-items: center;
      border: white;
      padding: 8px 20px;
      height: 40px;
      border-radius: 8px;
      background: var(
         --blue-purple-purple-1,
         linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
         #5770cb
      );
      color: var(--neutral-01, #fcfcfc);
      font-family: Poppins;
      font-size: 14px;
   }

   .newMemberButton:hover {
      color: var(--grey-white, #fff);
   }

   @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
   }
}

.filtersWrapper {
   display: flex;
   padding: 20px 16px;
   flex-direction: column;
   justify-content: flex-end;
   align-items: flex-start;
   gap: 24px;
   align-self: stretch;
   border-radius: 8px;
   border: 1.5px solid var(--Primary-Main-Blue-300, #cad6ff);
   background: var(--Primary-Main-Blue-100, #f9faff);
   margin-bottom: 15px;

   .filterSelect {
      :global .ant-select-selector {
         display: flex;
         align-items: center;
         margin-left: 10px;
         width: 180px !important;
         border-radius: 8px;
      }
   }

   .filterButton {
      display: flex;
      margin-left: 10px;
      height: 41px;
      padding: 12px 18px;
      align-items: center;
      border-radius: 28px;
      background: var(
         --blue-purple-purple-1,
         linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
         #5770cb
      );
      color: var(--grey-white, #fff);
      font-family: Poppins;
   }

   .filterButton:hover {
      color: var(--grey-white, #fff);
   }
}

.placeholderInput::placeholder {
   color: var(--greys-dark-gray, #707073);
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
}

.styledTitle {
   color: var(--blue-purple-purple-1, #5770cb);
   font-family: Poppins;
   font-size: 12px;
   font-style: normal;
   font-weight: 600;
   line-height: 24px;
   /* 200% */
   margin-left: 7px;
}

.styledDataIndex {
   color: var(--greys-dark-gray-25, #404040);
   font-family: Poppins;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
}

.userManagementHeader {
   position: relative;
   width: 100%;
   height: 190px;
   //   padding: 70px 20px 70px 30px;
   max-height: 378px;
   display: flex;
   justify-content: space-between;
   background-color: #d9e3ff;
}

.organizationsBannerInfo {
   flex-grow: 7;
   text-align: center;
   height: 100%;
   display: flex;
   align-items: center;

   h2 {
      color: #5770cb;
      font-family: Poppins;
      font-size: 32px;
      font-weight: 600;
      padding: 40px;
   }
}
.organizationsBannerImage {
   img {
      width: 100%;
      height: 100%;
   }
}

.firstRowWrapper h3 {
   color: var(--Blue-purple-Purple-1-or-Graph-1, #5770cb);

   /* Basic/Title */
   font-family: Poppins;
   font-size: 22px;
   font-style: normal;
   font-weight: 600;
   line-height: 144%; /* 31.68px */
}

.clearFiltersButton {
   color: var(--Primary-Blueberry, #5492ff);
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px; /* 157.143% */
   border: none;
   background-color: transparent;
   box-shadow: none;
}

.toggleButton {
   color: var(--Primary-Main-Blue, #4158cf);
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px; /* 157.143% */
   border: none;
   background-color: transparent;
   box-shadow: none;
}

.containerFilters {
   display: flex;
   gap: 20px;
   width: 100%;
}
.filtersContainer {
   display: flex;
   flex-wrap: wrap;
   gap: 20px;
}
@media (max-width: 500px) {
   .containerFilters {
      flex-direction: column;
   }
   .filtersWrapper {
      margin-top: 20px;
   }
   .filterSelect {
      width: auto;
   }
}

.styledDataIndex1 {
   color: var(--Neutrals-Black, #3a3737);
   font-family: Poppins;
   font-size: 12px;
   font-style: normal;
   font-weight: 600;
   line-height: 144%; /* 17.28px */
}

.styledDataIndex2 {
   color: var(--Neutrals-Gray-300, #979797);
   font-family: Poppins;
   font-size: 12px;
   font-style: normal;
   font-weight: 500;
   line-height: 144%; /* 17.28px */
}

.circleName {
   display: flex;
   width: 40px;
   height: 40px;
   flex-direction: column;
   align-items: flex-end;
   border-radius: 100px;
   background: var(--Volcano-2, #ffd8bf);
   color: var(--Volcano-6, #fa541c);
   text-align: center;
}
.circleLetter {
   display: flex;
   flex-direction: column;
   justify-content: center;
   flex: 1 0 0;
   align-self: stretch;
   color: var(--Volcano-6, #fa541c);
   text-align: center;

   /* H5/regular */
   font-family: Roboto;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 24px; /* 150% */
}

.rowName {
   display: flex;
   height: 56px;
   padding: 16px 10px;
   align-items: center;
   gap: 10px;
   align-self: stretch;
   min-width: 300px;
}

.firstDivNav {
   display: flex;
}

.firstDivNav div {
   margin-right: 20px;
}
.activeTab {
   color: var(--Primary-Main-Blue-600, #003daa);
   font-family: Poppins;
   font-size: 15px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px; /* 160% */
   letter-spacing: -0.15px;
}

.inactiveTab {
   color: var(--Neutrals-Gray-400, #777);
   font-family: Poppins;
   font-size: 15px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px; /* 160% */
   letter-spacing: -0.15px;
}

.activeLine {
   border-bottom: 2px solid #003daa; /* Active tab line color */
   width: 100%;
}

.inactiveLine {
   border-bottom: 2px solid grey; /* Inactive tab line color */
   width: 100%;
}

.newMemberHeader {
   color: var(--Blue-purple-Purple-1-or-Graph-1, #5770cb);
   font-family: Poppins;
   font-size: 18px;
   font-style: normal;
   font-weight: 600;
   line-height: 144%; /* 25.92px */
}

.buttonsDiv {
   display: flex;
   justify-content: center;
   align-items: flex-start;
   gap: 20px;
   align-self: stretch;
}
.buttonCancel {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   border: 1px solid var(--Blue-purple-Purple-1-or-Graph-1, #5770cb);
   color: var(--Blue-purple-Purple-1-or-Graph-1, #5770cb);
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px; /* 157.143% */
}

.buttonsCreateProfile {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background: var(--Blue-purple-Purple-1-or-Graph-1, #5770cb);

   /* Elevation/Buttons */
   box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
   color: var(--character-primary-inverse, #fff);
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px; /* 157.143% */
}
.buttonsCreateProfile:hover {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background: var(--Blue-purple-Purple-1-or-Graph-1, #5770cb);

   /* Elevation/Buttons */
   box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
   color: var(--character-primary-inverse, #fff) !important ;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px; /* 157.143% */
}

.addMultipleDiv {
   display: flex;
   width: 100%;
   height: 100%;
   padding: 20px;
   align-items: center;
   gap: 40px;
   border-radius: 8px;
   border: 1px solid var(--Secondary-Call-to-action-Orange, #ee903a);
   background: var(--Secondary-Call-to-action-Orange-300, #fff8e8);
   margin-bottom: 20px;
}

.addMultipleText {
   color: var(--Secondary-Call-to-action-Orange, #ee903a);
   font-family: Lato;
   font-size: 16px;
   font-style: normal;
   font-weight: 700;
   line-height: 22px; /* 137.5% */
}

.addMultipleButton {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background: var(--Secondary-Call-to-action-Orange, #ee903a);

   /* Elevation/Buttons */
   box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
   color: var(--character-primary-inverse, #fff);
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px; /* 157.143% */
}
.addMultipleButton:hover {
   display: flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   border-radius: 8px;
   background: var(--Secondary-Call-to-action-Orange, #ee903a) !important;

   /* Elevation/Buttons */
   box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
   color: var(--character-primary-inverse, #fff) !important;
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 22px; /* 157.143% */
}

.uploadDraggerHeader {
   color: var(--Primary-Main-Blue, #4158cf);
   text-align: center;
   font-family: Poppins;
   font-size: 16px;
   font-style: normal;
   font-weight: 600;
   line-height: 24px; /* 150% */
}

.uploadDraggerText {
   color: var(--Neutrals-Gray-400, #777);
   text-align: center;
   font-family: Poppins;
   font-size: 14px;
   font-style: normal;
   font-weight: 600;
   line-height: 24px; /* 171.429% */
}

.uploadDragger {
   border-radius: 8px;
   border: 2px dashed var(--Primary-Main-Blue-400, #b3bcec);
   background: var(--Primary-Blueberry-200, #f1f6ff);
}
.uploadDraggerIcon {
   display: flex;
   width: 44px;
   height: 44px;
   padding: 8px;
   justify-content: center;
   align-items: center;
   gap: 10px;
   border-radius: 80px;
   background: #fff;
}

.customDisabledInput {
   background-color: #fff !important; /* Match disabled background color */
/* Match disabled border color */
   color: black !important; /* Match disabled text color */
   cursor: default !important; /* Change cursor style */
   background: #fff !important;
}

/* Custom styles for disabled Select */
.customDisabledSelect {
   background-color: #fff !important;
   border: 1px solid #d9d9d9 ; /* Match disabled border color */
   color: black !important; /* Match disabled text color */
   cursor: default !important; /* Change cursor style */
}

