.online-indicator {
   display: flex;
   align-items: center;
   gap: 6px;

   &--status {
      background-color: #fff9f0;
      padding: 3px 10px 3px 10px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      gap: 5px;

      &---indicator {
         width: 10px;
         height: 10px;
         border-radius: 50%;
         display: inline-block;
      }
   }

   &--info {
      color: #5770cb;
      font-size: 16px;
      cursor: pointer;

      &:hover {
         scale: 1.1;
         transition: 0.2s ease-in-out;
      }
   }
}
