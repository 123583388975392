.number-submission-type-wrapper {
   &--number {
      max-width: 304px;
      width: 100%;
      border-color: #999999;
      padding-right: 75px;

      &-handler-wrap {
         opacity: 1 !important;
         flex-direction: row-reverse !important;
         width: 80px !important;

         & span {
            height: 100% !important;
            border-block-start: none !important;
         }
      }
   }

   &--error {
      display: block;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      color: #ff4d4f;
   }
}
