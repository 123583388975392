.deleted-modal-button {
   display: flex;
   align-items: center;
   border-radius: 8px;
   border-color: #4158cf;
   color: #4158cf;
   padding: 5px 5px;
   height: 40px;

   & .anticon {
      display: none;
   }

   @media (min-width: 680px) {
      padding: 10px 16px;
      height: 44px;

      & .anticon {
         display: inline-block;
      }
   }

   &_label {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
   }
}
