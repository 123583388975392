//
// Aside
//

// General Mode(all devices)
.aside {
   box-shadow: rgba(17, 17, 26, 0.1) 1px 0px 1px;
   background-color: $kaporg-dark-green;
   transition: get($aside-config, base, transition);
   .menu-item {
      margin: 4px !important;
   }
   .svg-icon i.green::before {
      color: var(--kaporg-dark-green) !important;
   }
   .aside-menu {
      background-color: $kaporg-white;
   }

   .aside-menu-left {
      background-color: $kaporg-dark-green;
   }

   .svg-icon i::before {
      color: $kaporg-white;
   }
   .btn.btn-clean:not(:disabled):not(.disabled):active:not(.btn-text),
   .btn.btn-clean:not(:disabled):not(.disabled).active,
   .show > .btn.btn-clean.dropdown-toggle,
   .show .btn.btn-clean.btn-dropdown {
      background-color: rgba(200, 200, 200, 0.41);
   }

   .btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled),
   .btn.btn-clean:focus:not(.btn-text),
   .btn.btn-clean.focus:not(.btn-text) {
      background-color: rgba(200, 200, 200, 0.41);
   }
   .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link .menu-arrow {
      color: #ffffff !important;
   }
   .aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-arrow {
      color: #DF8344 !important;
   }
   .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-arrow{
      color: #DF8344 !important;

   }
   .aside-menu .menu-nav > .menu-item > .menu-link .menu-arrow{
      color: #DF8344 !important;

   }
.menu-nav{
   .menu-item{
            a{
               &:hover{
                  .menu-text{
                        color: #D6DEFF !important;
                  }
               }
              
            }
   }
}
   .aside-menu
      .menu-nav
      > .menu-item
      .menu-submenu
      .menu-item.menu-item-open
      > .menu-link
      .menu-bullet.menu-bullet-dot
      > span {
      background-color: #252938  !important;
   }
   .aside-menu
      .menu-nav
      > .menu-item
      .menu-submenu
      .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active)
      > .menu-link
      .menu-bullet.menu-bullet-dot
      > span {
      background-color: #D6DEFF  !important;
   }

   .aside-menu
   .menu-nav
   > .menu-item
   .menu-submenu
   .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
   > .menu-link
   .menu-bullet.menu-bullet-dot
   > span {
   background-color: #D6DEFF  !important;
}

.aside-menu
.menu-nav
> .menu-item
.menu-submenu
.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
> .menu-link

> span {
color: #D6DEFF  !important;
}

   // Aside Secondary Panel enabled
   .aside-secondary-enabled & {
      width: get($aside-config, base, primary-width) + get($aside-config, base, secondary-width);
   }

   // Aside Secondary Panel disabled
   .aside-secondary-disabled & {
      width: get($aside-config, base, primary-width);
   }

   // Aside Primary Panel
   .aside-primary {
      width: get($aside-config, base, primary-width);
      flex-shrink: 0;

      // Aside Secondary Panel disabled
      .aside-secondary-enabled & {
         border-right: 1px solid $kaporg-dark-green;
      }
   }

   // Aside Secondary Panel
   .aside-secondary {
      overflow: hidden;
      background-color: $kaporg-white;

      // Workspace
      .aside-workspace {
         width: get($aside-config, base, secondary-width);
         flex-shrink: 0;
      }

      // Menu
      .aside-menu {
         @include perfect-scrollbar-ver-offset(4px);
      }
   }
}
.burgerIcon{
  font-size: 26px;
  color: #DF8344;
  transition: all .3s ease-out;
  &:hover{
      color: #394261;
  }
}
.workspace-aside {
   .aside-brand .brand-logo img {
      padding: 1px;
   }

   .btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
   .btn.btn-hover-primary:focus:not(.btn-text),
   .btn.btn-hover-primary.focus:not(.btn-text) {
      background-color: $kaporg-green-lighter !important;
      border-color: $kaporg-green-lighter !important;
   }

   .aside-toggle i,
   .aside .aside-toggle.aside-toggle-active i {
      background-color: unset !important;
   }

   .menu-nav > .menu-item > .menu-link .menu-text {
      color: rgba(159, 159, 159, 1) !important;
   }
   .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-text {
      color: rgba(159, 159, 159, 1) !important;
   }

   .aside-menu
      .menu-nav
      > .menu-item
      .menu-submenu
      .menu-item.menu-item-active
      > .menu-link
      .menu-text {
      color: $kaporg-dark-green !important;
      font-weight: 500 !important;
   }
   .aside-menu .menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-arrow {
      color: rgba(159, 159, 159, 1) !important;
   }

   .aside-menu
      .menu-nav
      > .menu-item
      .menu-submenu
      .menu-item.menu-item-open
      > .menu-link
      .menu-bullet.menu-bullet-dot
      > span {
      color: rgba(159, 159, 159, 1) !important;
   }

   .aside-menu
      .menu-nav
      > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
      > .menu-link
      .menu-arrow {
      color: rgba(159, 159, 159, 1) !important;
   }
 

   .aside-menu
      .menu-nav
      > .menu-item
      .menu-submenu
      .menu-item.menu-item-open
      > .menu-link
      .menu-arrow {
      color: rgba(159, 159, 159, 1) !important;
   }

   .menu-item.menu-item-active > .menu-link .menu-text {
      color: $kaporg-dark-green !important;
      font-weight: 500 !important;
   }

   .aside-menu
      .menu-nav
      > .menu-item.menu-item-active
      > .menu-link
      .menu-icon.svg-icon
      svg
      g
      [fill] {
      fill: $kaporg-dark-green;
   }

   .aside-menu
      .menu-nav
      > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
      > .menu-link
      .menu-icon.svg-icon
      svg
      g
      [fill] {
      fill: $kaporg-dark-green;
   }

   .aside-menu
      .menu-nav
      > .menu-item.menu-item-open
      > .menu-heading
      .menu-icon.svg-icon
      svg
      g
      [fill],
   .aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon.svg-icon svg g [fill] {
      fill: $kaporg-dark-green;
   }

   .aside-menu
      .menu-nav
      > .menu-item
      .menu-submenu
      .menu-item.menu-item-active
      > .menu-link
      .menu-bullet.menu-bullet-dot
      > span {
      background-color: $kaporg-dark-green;
   }
}

.admin-aside {
   .menu-nav {
      height: 65vh;
      overflow-y: auto;
   }
   .menu-nav > .menu-item.menu-item-open > .menu-link .menu-text {
      color: rgba(255, 255, 255, 1) !important;
   }

   .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link .menu-text {
      color: rgba(255, 255, 255, 1) !important;
   }

   .aside-brand {
      background-color: $kaporg-dark-green;
   }
}

body {
   background-color: #ffffff !important;
}
//Dark mode for brand
.dark-mode {
   .aside {
      background-color: #021c17 !important;
      .aside-menu {
         background-color: #032b26 !important;
         .menu-nav {
            .menu-section {
               .menu-text {
                  color: rgba(240, 240, 240, 0.92);
               }
            }
            .menu-item:active {
               .menu-link {
                  background: rgba(45, 49, 51, 0.36);
               }
            }
         }
      }
      .svg-icon i.green::before {
         color: #bfbfbf !important;
      }
      .menu-item.menu-item-active > .menu-link .menu-text {
         color: var(--kaporg-dark-green) !important;
         font-weight: 500 !important;
      }
   }
   .admin-aside {
      background-color: var(--kaporg-dark-green) !important;
      .aside-brand {
         background-color: var(--kaporg-dark-green) !important;
      }
      .aside-menu-left {
         background-color: var(--kaporg-dark-green) !important;
      }
   }
   .aside-secondary {
      background-color: #032b26;
      .aside-workspace {
         .tab-content {
            background-color: #032b26;
         }
      }
   }
   .content {
      background-color: rgb(2, 22, 18);
   }
   .container-fluid {
      background-color: #021c17;
   }
   .subheader {
      background-color: #011916;
   }
   .footer {
      background-color: #011916;
   }
   .subheader .subheader-search-bar .search-input {
      background-color: #032d25;
   }
   .table {
      color: var(--kaporg-lightest-green);
   }
   .form-group label {
      color: var(--kaporg-black-text);
   }
   .btn.sign-btn {
      background-color: var(--kaporg-lightest-green);
      color: black;
   }
   .form-control.form-control-solid {
      background-color: #0c110f;
   }
   .form-control.form-control-solid:focus {
      background-color: black;
      color: var(--kaporg-black-text);
   }
   .text-dark {
      color: var(--kaporg-white-color) !important;
   }
}

.aside-secondary-enabled .aside-admin {
   width: 287px;
   &.minimized {
      width: 94px;
      .aside-menu .menu-nav > .menu-item > .menu-heading .menu-text,
      .aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
         display: none;
      }
      .aside-menu .menu-nav > .menu-item > .menu-heading .menu-arrow,
      .aside-menu .menu-nav > .menu-item > .menu-link .menu-arrow {
         display: none;
      }
      .aside-menu .menu-nav > .menu-item > .menu-link {
         display: flex;
         justify-content: center;
         text-align: center;
      }
      .aside-menu .menu-nav .menu-section {
         padding: 0 12px;
         word-break: break-word;
      }
   }
}

.aside-secondary-enabled.aside-fixed.admin-layout .wrapper {
   padding-left: 287px;
   &.minimized {
      padding-left: 86px;
   }
}



@media only screen and (max-width: 991px) {
   .aside-secondary-enabled.aside-fixed.admin-layout .wrapper {
      padding-left: 0px;
      &.minimized {
         padding-left: 0px;
      }
   }
}

// Desktop Mode
@include media-breakpoint-up(lg) {
   .aside {
      // Fixed Aside Mode
      .aside-fixed & {
         position: fixed;
         top: 0;
         bottom: 0;
         left: 0;
         z-index: get($aside-config, base, zindex);
      }

      // Static Aside Mode
      .aside-static & {
         position: relative;
         z-index: 1;
      }

      // Minimized Aside Mode
      .aside-minimize & {
         width: get($aside-config, base, primary-width);
         transition: get($aside-config, base, transition);

         // Aside Primary Panel
         .aside-primary {
            border-color: transparent;
         }
      }

      // Aside Minimize Toggle Icon
      .aside-toggle {
         background-color: $kaporg-dark-green;
         border: $kaporg-dark-green;
         position: absolute;
         right: 0;
         bottom: 40px;
         height: 36px;
         width: 36px;
         margin-right: -(36px/2);
         z-index: get($aside-config, base, zindex) + 1;

         i {
            transition: $transition;
         }

         // Aside Minimize Toggle Icon Active State
         .aside-minimize &,
         &.aside-toggle-active {
            i {
               transition: $transition;
               transform: rotateZ(180deg) #{"/*rtl:ignore*/"};

               background-color: $kaporg-dark-green;
               border: $kaporg-dark-green;

               [direction="rtl"] & {
                  transform: rotateZ(-180deg) #{"/*rtl:ignore*/"};
               }
            }
         }
      }
   }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
   .aside {
      // Aside Primary Panel
      .aside-primary {
         background-color: $kaporg-dark-green;
         width: get($aside-config, base, primary-width-tablet-and-mobile);

         // Buttons
         .btn.btn-icon.btn-lg {
            width: 40px;
            height: 40px;

            .svg-icon {
               @include svg-icon-size(26px);
            }
         }
      }

      // Aside Secondary Panel
      .aside-secondary {
         // Workspace
         .aside-workspace {
            width: 100%;
            flex-shrink: 0;
            overflow: auto;
         }
      }

      // Aside Minimize Toggle Icon
      .aside-toggle {
         display: none;
      }
   }

   // Aside Secondary Panel enabled
   .aside-secondary-enabled {
      @include offcanvas-width(
         aside,
         get($aside-config, base, primary-width-tablet-and-mobile) +
            get($aside-config, base, secondary-width-tablet-and-mobile)
      );
   }

   // Aside Secondary Panel disabled
   .aside-secondary-disabled {
      @include offcanvas-width(aside, get($aside-config, base, primary-width-tablet-and-mobile));
   }
}

@media (max-width: 992px) {
   .aside-secondary-enabled.aside-fixed.admin-layout .wrapper {
      padding-left: 0;
   }
}

// Build Aside Menu(see mixin: src/sass/components/mixins/_menu-vertical.scss)
@include menu-ver-build-layout($aside-menu-config);
@include menu-ver-build-theme($aside-menu-config, default);

// Build Aside Offcanvas Panel for mobile mode
@include offcanvas-build(aside, tablet-and-mobile, get($aside-config, offcanvas-mobile));
