.preview-wrapper {
   z-index: 999 !important;
}

.loading-container {
   width: 100%;
   height: 100%;
   display: flex;
   place-content: center;
   place-items: center;
   padding: 20px 0px;
}

.survey-previewer {
   margin: 20px auto;
   width: 100%;
   max-width: calc(933px + 60px);
   padding: 0 13.5px;

   @media (min-width: 640px) {
      margin: 30px auto;
      padding: 0 20px;
   }

   @media (min-width: 768px) {
      margin: 30px auto;
      padding: 0 30px;
   }

   @media (min-width: 1024px) {
      margin: 40px auto 58px auto;
   }

   &--alert {
      margin-bottom: 20px;
      padding: 16px 40px;
      border-radius: 8px;
      border: 1px solid #ee903a;
      background: #fff8e8;
      font-style: normal;

      &-message {
         font-family: Poppins;
         color: #ee903a !important;
         font-size: 18px !important;
         font-weight: 600;
         margin: 0 !important;
      }
      &-description {
         font-family: Poppins;
         color: #ee903a !important;
         font-size: 16px;
         font-weight: 400;
      }
   }

   .survey-description {
      margin: 35px 5px;

      h6 {
         font-family: "Nunito Sans";
         font-style: normal;
         font-weight: 600;
         font-size: 24px;
         line-height: 28px;
         letter-spacing: -0.02em;
         text-transform: uppercase;
         color: var(--kaporg-black-text);
      }

      p {
         font-family: "Nunito Sans";
         font-style: normal;
         font-weight: 300;
         font-size: 20px;
         line-height: 28px;
         text-align: justify;
         letter-spacing: -0.02em;
         color: var(--kaporg-black-text);
      }

      @media (max-width: 1150px) {
         p,
         h6 {
            font-size: 16px;
         }
      }
   }

   p {
      margin: 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: var(--kaporg-black-text);
   }

   .photoPreview {
      margin-top: 22px;
      max-height: 200px;
   }

   .survey-meta-info {
      background: var(--Blue-purple-Purple-2, #9baceb);
      margin: 0 auto;
      border-radius: 8px 8px 0px 0px;
      overflow: hidden;

      .survey-icon {
         text-align: center;
         padding: 0;
         background-color: #9baceb;
         border-radius: 8px 0 0 8px;
         min-width: 100px !important;

         -webkit-box-shadow: -80px 0px 6px -80px #32323d3f inset;
         -moz-box-shadow: -80px 0px 6px -80px #32323d3f inset;
         box-shadow: -80px 0px 6px -80px #32323d3f inset;

         img {
            padding: 20px;
            width: 130px;
            margin: 0;
         }
      }

      .survey-banner-content {
         display: flex;
         width: 933px;
         min-height: 247px;
         padding: 32px 0px 32px 24px;
         align-items: center;
         gap: 24px;
         background: var(--Blue-purple-Purple-1, #5770cb);

         p {
            font-size: 10px;
            font-weight: 500;
            color: #9baceb;
         }

         hr {
            margin-top: 4px !important;
            margin-bottom: 4px !important;
            background-color: #cfd8f9 !important;
         }
      }
   }

   .survey-section {
      margin: 15px 10px;
      padding: 40px 20px;
      margin-bottom: 40px;
      background-color: #ffff;
      border-radius: 8px;

      .section-questions {
         margin-top: 50px;
      }

      .section-title {
         color: var(--kaporg-focus-green);
         font-family: "Nunito Sans";
         font-weight: 700;
         font-size: 24px;
         letter-spacing: 0.01em;
      }
   }

   .section-questions {
      .question-wrapper {
         margin-top: 45px;

         .question-text {
            max-width: 75%;
            font-family: "IBM Plex Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 144%;
            letter-spacing: 0.01em;
            color: var(--kaporg-black-text);
            margin-bottom: 8px;

            @media (max-width: 768px) {
               max-width: 95%;
            }
         }
      }
   }
}

@media print {
   .hide-on-print {
      display: none !important;
      padding: 0;
      margin: 0;
   }
}

.save-draft-modal {
   &--description {
      font-size: 18px;
      font-weight: 400;
      line-height: 25.92px;
      color: #707073;
      margin-bottom: 32px;
   }

   &--form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      gap: 22px;

      &---control {
         label {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            color: #404040;
         }
      }

      &---actions {
         margin-top: 58px;
         display: flex;
         justify-content: center;
         gap: 16px;

         &_cancel {
            font-size: 16px;
            font-weight: 500;
            color: #5770cb;
            border-color: #5770cb;
            padding: 6.4px 16px;
            height: auto;
         }
         &_save {
            font-size: 16px;
            font-weight: 500;
            padding: 6.4px 16px;
            height: auto;
            background-color: #5770cb;
         }
      }
   }
}

.show-drafts-modal {
   &--description {
      font-size: 18px;
      font-weight: 400;
      line-height: 25.92px;
      color: #707073;
      margin-bottom: 32px;
   }

   &--form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      gap: 22px;

      &---control {
         label {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            color: #404040;
         }
      }

      &---actions {
         margin-top: 16px;
         display: flex;
         justify-content: center;
         gap: 16px;

         &_cancel {
            font-size: 16px;
            font-weight: 500;
            color: #5770cb;
            border-color: #5770cb;
            padding: 6.4px 16px;
            height: auto;
            display: none;
         }
         &_save {
            font-size: 16px;
            font-weight: 500;
            padding: 6.4px 16px;
            height: auto;
            background-color: #5770cb;
         }
      }
   }

   @media (min-width: 640px) {
      &--description {
         text-align: center;
      }

      &--form {
         &---actions {
            margin-top: 32px;

            &_cancel {
               display: inline-block;
            }
         }

         @media (min-width: 768px) {
            &---actions {
               margin-top: 58px;
            }
         }
      }
   }
}
