.rating-submission-type {
   &--wrapper {
      overflow-x: auto;
      &-table {
         border-collapse: collapse; /* Merge borders */
         width: 100%; /* Adjust width as needed */

         &_radio {
            &-wrapper {
               padding: 8px 14px;
               border: 1px dashed transparent;
               border-radius: 6px;
               display: block;
               margin-inline: 0;
               display: flex;
               align-items: center;
               gap: 4px;

               font-family: Poppins;
               font-size: 14px;
               font-weight: 500;
               line-height: 21px;
               letter-spacing: 0.02em;
               color: #7c7c80;

               &:hover {
                  border: 1px dashed #d9d9d9;
               }
            }
            &-inner {
               width: 20px !important;
               height: 20px !important;
               border-width: 2px !important;
               border-color: #999999 !important;

               &::after {
                  transform: scale(0.5) !important;
                  background-color: #5770cb !important;
               }
            }

            &-checked {
               span {
                  background-color: #fff !important;
                  border-color: #5770cb !important;
                  border-width: 2px !important;
               }
            }
         }

         & thead {
            & th {
               padding: 12px;
               background-color: #eef2ff;
               font-family: Poppins;
               font-size: 14px;
               font-weight: 500;
               line-height: 20.16px;
               letter-spacing: 0.01em;
               color: #5770cb;
            }

            & th:first-child {
               border-top-left-radius: 5px;
            }
            & th:last-child {
               border-top-right-radius: 5px;
            }
         }

         & tbody {
            border: 1px solid transparent;

            &.error {
               border: 1px solid #ff4d4f;
            }

            & tr {
               border-bottom: 1px solid #d9d9d9;

               &.error {
                  border-bottom: 1px solid #ff4d4f;
               }
            }
            & td {
               font-family: Poppins;
               font-size: 14px;
               font-weight: 500;
               line-height: 20.16px;
               letter-spacing: 0.01em;
               color: #252938;
               padding: 8px 12px;
               vertical-align: top;
            }
         }
      }
   }

   &--error {
      display: block;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      color: #ff4d4f;
   }
}
