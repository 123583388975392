.main-error-boundary {
   max-width: 525px;
   width: 100%;
   margin: auto;
   display: block;
   padding: 0.5rem;

   &_info {
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
         gap: 16px;
      }

      h1 {
         align-self: stretch;
         color: #5770cb;
         font-family: Poppins;
         font-size: clamp(1.75rem, 4.464vw + 0.857rem, 3rem) !important;
         font-style: italic;
         font-weight: 700;
         line-height: normal;
         letter-spacing: 0.48px;
      }

      p {
         align-self: stretch;
         color: #555;
         font-family: Poppins;
         font-size: 16px;
         font-style: normal;
         font-weight: 500;
         line-height: 26px;
         margin: 0;
         @media (min-width: 768px) {
            font-size: 18px;
         }
      }
   }
   &_actions {
      margin: 10px 0;
      padding: 0 3rem;
      display: flex;
      justify-content: center;
      gap: 16px;
      flex-direction: column;

      @media (min-width: 768px) {
         flex-direction: row;
         margin: 32px 0;
         gap: 32px;
      }
      &--back {
         display: flex;
         border-radius: 8px;
         height: 44px;
         padding: 4px 16px;
         justify-content: center;
         align-items: center;
         background: #5770cb;
         box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
      }
      &--reload {
         display: flex;
         height: 44px;
         padding: 4px 16px;
         justify-content: center;
         align-items: center;
         border-radius: 8px;
         border: 1px solid #5770cb;
         color: #5770cb;
      }
   }
   &_image {
      svg {
         width: 100%;
         height: auto;
      }
   }
}
