.project-selection {
   &_table-actions {
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;
      gap: 2rem;

      @media (min-width: 680px) {
         flex-direction: row;
      }

      &--filters {
         height: 100%;
         width: 100%;
         display: flex;
         gap: 12px;
         flex-direction: column;
         @media (min-width: 680px) {
            flex-direction: row;
         }

         .search-input {
            width: 100%;
            height: 40px;
            border-radius: 8px;
            background: #f2f2f2;
            border: none;
            flex-shrink: 0;

            @media (min-width: 680px) {
               width: 300px;
            }
            @media (min-width: 1024px) {
               width: 381px;
            }
         }
         .filter-input {
            border-radius: 8px;
            background: #f2f2f2;
            border: none;
         }
         .search-button {
            width: 100%;
            border: none;
            border-radius: 8px;
            background: #d9e3ff;
            color: #5770cb;
            @media (min-width: 680px) {
               width: auto;
            }
         }
      }

      &--extra {
         width: 100%;
         @media (min-width: 680px) {
            width: auto;
         }

         .deleted-projects-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            @media (min-width: 680px) {
               width: auto;
            }
         }
      }
   }
}

.projects-selection-page {
   padding: 3% 8%;
   margin: 2%;
   background-color: white;
   box-shadow: 0px 2px 8px 0px #e8e8e8;
   border-radius: 8px;

   .heading {
      color: var(--blue-purple-dark-purple, #252938);
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      /* 120% */
      letter-spacing: -0.4px;
      margin-top: 20px;
   }

   .ant-table-row-expand-icon-cell {
      width: 30px !important;
   }

   .ant-table-row {
      cursor: pointer !important;

      td {
         &:first-child {
            margin-top: 7px;
            justify-content: center;
            align-items: center;
            width: 30px;
         }
      }
   }

   .create-button {
      display: flex;
      justify-content: flex-end;
   }

   .new-projects-button {
      justify-content: center;
      align-items: center;
      border: none;
      background: #d6deff;
      color: #5770cb;
      min-width: 150px;
      height: 45px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      span {
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 5px;
      }

      &:hover {
         background-color: #9baceb;
         color: white;
      }
   }

   // projects  table

   .ant-table-tbody tr :nth-child(3) {
      text-align: start !important;
      padding-right: 0 !important;
   }

   table {
      .table-layout {
         table-layout: fixed;
         width: 100% !important;
      }
   }

   th,
   td {
      width: 100px;
      padding: 0;
   }

   // @media (max-width: 768px) {
   //    .table-control-group {
   //       display: block !important;
   //    }
   // }

   @media screen and (max-width: 1000px) {
      .table-control-group {
         flex-direction: column;

         .table-control {
            margin-bottom: 16px;
         }

         .filter-search-group {
            flex-direction: column;

            .projects-list-search-button {
               margin-top: 8px;
            }
         }
      }
   }

   tr {
      th {
         width: 25%;
         background-color: #d6deff !important;
         color: "#576596";
      }
   }

   .ant-table-thead > tr > td {
      background-color: #d6deff !important;
   }

   // .ant-select-selection-search {
   //    width: 140px !important;
   // }

   .table-control-group {
      flex-wrap: wrap;

      .table-control {
         width: 100%;
         gap: 15px;

         .ant-select-selector {
            display: flex;
            align-items: center;
            height: 40px !important;
         }

         button {
            border: none;
            height: 40px;
            width: 90px;
            background: #576596;
            color: white;
            border-radius: 50px;
            padding: 5px;
            transition: all 0.3s ease-in-out;

            &:hover {
               background-color: #394261;
            }
         }

         .filter-search-group {
            width: 100%;
            display: flex;
            gap: 15px;

            .ant-select {
               width: 35%;
               min-width: 150px;
               margin-right: 5px;
               margin-bottom: 10px;
            }

            @media (max-width: 420px) {
               .ant-select {
                  width: 80%;
               }
            }
         }
      }

      .projects-list-search-button {
         display: block !important;

         button {
            display: flex !important;
            justify-content: center !important;
            align-items: center;
            border: none;
            height: 40px;
            width: 90px;
            border-radius: 8px;
            background: #d6deff;
            color: #5770cb;
            padding: 5px;
            transition: all 0.3s ease-in-out;
            border-radius: 8px;
            font-weight: 500;

            margin-left: 10px;

            &:hover {
               background-color: #9baceb;

               color: white;
            }
         }
      }
   }

   .ant-pagination {
      display: flex;
      justify-content: center;
   }

   @media (max-width: 879px) {
      .table-control {
         flex-direction: column;
      }

      .table-control-group {
         flex-direction: column-reverse;

         .new-member-button {
            margin-bottom: 10px;
            margin-left: 0 !important;
            width: 100% !important;
         }
      }
   }

   @media (max-width: 419px) {
      .filter-search-group {
         display: block !important;

         .ant-select-selector {
            margin-right: 5px;
            margin-top: 5px;
         }
      }

      .projects-list-search-button {
         margin-top: 15px;
      }
   }
}

.new-project-page {
   align-items: center;
   width: 60%;
   margin: 5% auto;
   padding: 4% 8%;
   gap: 30px;
   border-radius: 24px;
   color: #707073;
   background-color: white;
   box-shadow: 0px 2px 8px 0px #ababab;

   h2 {
      text-align: center;
      margin-bottom: 2%;
      font-weight: 600;
   }

   .project-description {
      display: flex;
      justify-content: center;
      width: 100%;

      p {
         font-weight: 500;
         width: 30% !important;
         min-width: 250px;
         text-align: center;
         margin-bottom: 6%;
      }

      @media (max-width: 260px) {
         p {
            width: 90%;
            min-width: 100px;
         }
      }
   }

   .project-import-build-box-group {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;

      .new-project-box {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         background-color: #252938;
         width: 210px;
         height: 160px;
         border-radius: 20px;

         .project-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            background: white !important;
            padding: 15px;
            border-radius: 50%;
            cursor: pointer;

            img {
               width: 25px;
               height: auto;
            }
         }

         h4 {
            margin-top: 13px;
            color: white;
         }
      }
   }
}

@media (max-width: 1368px) {
   .new-project-page {
      width: 75%;
   }
}

@media (max-width: 468px) {
   .new-project-page {
      width: 95%;
      text-align: center;
   }
}

.edit-project-page {
   padding-top: 5% !important;

   h2 {
      font-size: 26px;
      font-weight: 600;
      color: #5770cb !important;
   }
}

.create-new-project-page {
   margin: 5% auto;
   width: 70%;
   box-shadow: 0px 2px 8px 0px #e0dede;
   border-radius: 28px;
   background-color: white !important;
   padding: 0% 0 4% 0;
   display: flex;
   justify-content: center;
   overflow-x: hidden !important;
   position: relative;

   .beneficiaries-button {
      display: flex;
      align-items: center;
      width: 180px;
      height: 40px;
      background: #d9e3ff;
      color: #5770cb;
      font-weight: 500;
   }

   .download-format {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
      margin: 0 auto;
      width: 60%;
      height: 28%;
      position: relative;
      background-color: #ece5fd;
      border-radius: 0 0 8px 8px;
      font-weight: 500;
      font-size: 14px;
      text-align: center;

      .download-line {
         width: 100%;
         height: 10px;
         border-radius: 0 0 8px 8px;
         left: 0;
         top: 0;
         background-color: #8153f3;
         position: absolute;
      }

      .ant-btn {
         display: flex;
         align-items: center;
         background-color: #8153f3;
      }
   }

   @media (max-width: 768px) {
      .download-format {
         width: 90% !important;
      }
   }

   h2 {
      font-size: 26px;
      font-weight: 600;
      color: #576596;
   }

   .ant-form-horizontal {
      display: flex;
      flex-direction: column;
      width: 60%;

      .ant-row {
         display: block;

         label {
            font-weight: 600;
         }
      }
   }

   .ant-col {
      margin: 0 !important;

      .add-button {
         display: flex;
         justify-content: center;
         align-items: center;
         background-color: #576596;
         border: none;
         color: white;
         height: 40px;

         &:hover {
            background-color: #48506f;
            color: white;
         }
      }
   }

   .create-survey-button-group {
      display: flex;
      justify-content: center;
      width: 100%;
      gap: 10px;

      .ant-col {
         margin: 0 !important;
      }

      .previous {
         background-color: transparent;
         color: #252938;
         border: 1px solid #cdced2;
         box-shadow: none;

         &:hover {
            border-color: #48506f;
         }
      }

      .next-button {
         background-color: #576596;

         &:hover {
            background-color: #48506f;
            color: white;
         }
      }

      button {
         width: 130px;
         height: 40px;
         transition: all 0.3s ease-in-out;
      }

      @media screen and (max-width: 600px) {
         align-items: center;
         flex-direction: column;
         gap: 0;
      }
   }
}

@media (max-width: 568px) {
   .create-new-survey-page {
      width: 90%;
   }

   .ant-form-horizontal {
      width: 80% !important;
   }
}

@media (max-width: 376px) {
   .create-new-survey-page {
      width: 100%;
   }

   .ant-form-horizontal {
      width: 90% !important;
   }
}

.new-project-page-edit-modal {
   h2 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 8%;
   }

   label {
      font-weight: 600;
   }

   button {
      width: 120px;
   }

   .edit-modal-cancel {
      background-color: transparent;
      color: #707073;
      border: 1px solid #70707346;
      transition: all 0.3s ease-in-out;

      a {
         color: #707073;
      }

      &:hover {
         border-color: #252938 !important;
      }
   }

   .edit-modal-submit {
      border: none;
      height: 40px;
      background: #576596;
      color: white;
      border-radius: 8px;
      padding: 5px;
      transition: all 0.3s ease-in-out;

      margin-left: 10px;

      &:hover {
         background-color: #394261;
      }
   }
}

.projects-selection-page {
   overflow-x: auto !important;

   margin: 0 auto;
   margin-top: 3%;
   margin-bottom: 3%;
   border-radius: 10px;
   border: 1px solid #e8e8e8;
   background: #fff;
   box-shadow: 0px 4px 12px 0px rgba(129, 129, 129, 0.25);
   min-height: 300px;
   width: 95%;
   padding: 4%;

   .line {
      width: 100%;
      height: 1px;
      background-color: #d6deff;
      margin-bottom: 20px;
   }

   table {
      @media (max-width: 530px) {
         .table-layout {
            table-layout: fixed;
            width: 100% !important;
         }
      }

      td {
         padding: 16px 0 16px 16px !important;
      }

      .ant-collapse-wrapper {
         background-color: white;
         box-shadow: none;
      }

      .ant-table-expanded-row {
         .ant-table-cell {
            background-color: white;

            &:hover {
               background-color: white;
            }
         }

         .ant-collapse {
            width: 98.5%;
            float: right;
         }
      }

      table {
         // width: 100%;
         // float: right;

         box-shadow: 0px 4px 10px 0px #a5b1d9;
         border-bottom-left-radius: 12px !important;
         border-bottom-right-radius: 12px !important;
         border: none;

         .ant-table-row:hover {
            box-shadow: none !important;
         }
      }

      table tbody tr:last-child td:first-child,
      table tbody tr:last-child td:last-child {
         border: none;
      }
   }

   .ant-table-tbody {
      padding: 0;
   }

   .newtable {
      div {
         border: none;
      }

      tbody {
         td {
            .ant-table-cell:nth-last-child(2) {
               display: flex;
            }
         }
      }

      tbody tr:hover {
         box-shadow: none !important;
      }

      thead {
         tr {
            th {
               width: 25%;
               background-color: #d6deff !important;
               color: #576596 !important;
            }

            td {
               width: 25%;
               background-color: #d6deff !important;
               color: #576596 !important;
            }
         }
      }

      .ant-table-cell {
         background-color: white;
         border: none;
      }

      .ant-table-thead > tr > td {
         background-color: #d6deff !important;
      }

      .ant-select-selection-search {
         width: 140px !important;
      }
   }
}

@media (max-width: 768px) {
   .survey-icons-group {
      span {
         padding-bottom: 10px;
      }
   }
}

.small-icons {
   outline: none !important;
   border: none !important;
}

.project-dashboard-page {
   height: 100%;

   .project-tools {
      a {
         width: 215px;
      }

      .tool-box {
         color: var(--Blue-purple-Purple-1, #5770cb);
         display: flex;
         height: 52px;
         padding: 8px 32px 8px 24px;
         justify-content: center;
         align-items: center;
         gap: 8px;
         border-radius: 8px;
         border: 1px solid var(--Blue-purple-Purple-3, #d9e3ff);
         background: var(--Blue-purple-Purple-4, #eef2ff);
         box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);

         h5 {
            font-size: 14px;
            margin: 0;
            word-wrap: break-word;
         }

         .tools-icon {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            font-size: 24px;
            background: rgba(87, 112, 203, 0.2);
            padding: 10px;
            border-radius: 80px;

            path {
               stroke: #5770cb;
               stroke-width: 1.5px;
            }
         }

         .tool-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
         }

         @media (max-width: 888px) {
            flex: 1 1;
         }
      }
   }

   .project-information {
      margin: 50px 0 !important;
      border: 1px solid #9baceb;
      border-top: none;
      border-radius: 8px;
      background: rgba(217, 227, 255, 0.4) !important;

      .general-information {
         width: 100% !important;

         .project-top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .project-top-title {
               display: flex;
            }

            button {
               color: #465aa2;
               width: 30px;
               height: 30px;
               display: flex;
               align-items: center;
               justify-content: center;
               font-size: 16px;
               border-radius: 8px;
               border: 1px solid #d9e3ff;
               background: #eef2ff;
               box-shadow: 0px 1px 6px 0px rgba(165, 177, 217, 0.77);

               a {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #465aa2;
               }
            }
         }

         h3 {
            color: #ffffff;
         }

         background-color: #5770cb;
         padding: 30px;
         border-radius: 8px 8px 0px 0px;
         font-size: 12px;
         color: #ffffff;
      }

      .tickets {
         gap: 15px;
         display: inline-flex;
         flex-wrap: wrap;
         color: #5770cb;

         span {
            background-color: #eef2ff;
            border-radius: 30px;
            padding: 5px 15px;
            display: flex;
            align-items: center;

            .ticket-icons {
               padding: 4px !important;
            }
         }
      }

      .information-bottom {
         position: relative;
         margin-top: 15%;
         display: flex;
         align-items: center;
         justify-content: space-between;

         &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            top: 0;
            background-color: #d6deff;
         }

         span,
         button {
            margin-top: 30px;
         }

         button {
            display: inline-flex;
            padding: 10px 18px;
            align-items: center;
            gap: 5px;
            border-radius: 5px;
            background: #eef2ff;
            box-shadow: 0px 3px 6px 0px #a5b1d9;
            border: none;
            transition: all 0.8 ease;

            &:hover {
               background: #cbd5f4;
            }
         }

         .ant-tag {
            border-radius: 30px;
         }
      }

      @media screen and (max-width: 1200px) {
         .collection-timeline {
            margin-top: 20px;
         }
      }

      .collection-timeline {
         h2 {
            color: #1b7df0;
         }

         .ant-progress-bg {
            background-color: #1b7df0 !important;
         }
      }

      .collection {
         background-color: #fff;
         color: #252938;
         height: 100%;
         filter: drop-shadow(0px 4px 12px #a5b1d9);
         padding: 30px;
         border-radius: 8px;
         font-size: 12px;
         display: flex;
         align-items: center;
         justify-content: space-around;
         gap: 35px;

         .ant-progress-inner {
            background-color: rgba(0, 0, 0, 0.06) !important;
         }

         .collection-goal-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: rgba(69, 165, 102, 0.16);
            width: 40px;
            height: 40px;
         }

         .collection-progress-bar {
            border-radius: 54px;

            .ant-progress-bg {
               background: #45a566;
               height: 10px !important;
            }
         }

         .percentage-text {
            color: #45a566;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            /* 125% */
            /* 166.667% */
         }

         .progress-numbers {
            color: rgba(0, 0, 0, 0.3);
            text-align: right;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 34px;
            margin-bottom: 0px;

            span {
               color: #45a566;
               margin: 0;
            }
         }

         .progress-text {
            color: var(--greys-mid-gray, #999);
            text-align: right;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            /* 150% */
         }

         .time-line-icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: rgba(27, 125, 240, 0.16);
            width: 40px;
            height: 40px;
         }

         .ant-progress-text {
            display: block;
            margin: 5px 0 0 0;
         }

         span {
            margin-right: 25px;
            margin-left: 13px;
            position: relative;

            &.first::after {
               content: "";
               width: 10px;
               height: 10px;
               position: absolute;
               top: 20%;
               border-radius: 50%;
               left: -12px;
               background-color: rgb(69 165 102 / 60%);
            }

            &.second::after {
               content: "";
               width: 10px;
               height: 10px;
               position: absolute;
               top: 20%;
               border-radius: 50%;
               left: -12px;
               background-color: rgba(0, 0, 0, 0.2);
            }
         }

         .ant-progress-inner {
            background-color: #d6deff;
         }

         .ant-progress-bg {
            background-color: #45a566;
         }

         .collection-goal-completed {
            height: 100% !important;

            h5 {
               color: #5770cb;
               text-align: right;
               font-size: 18px;
               font-weight: 600;
            }

            p {
               color: #999;
               text-align: right;
               font-size: 12px;
            }
         }
      }

      .collection-timeline {
         height: 100%;

         .ant-progress-text {
            display: none;
         }

         h5 {
            color: #5770cb;
            text-align: right;
            font-size: 18px;
            font-weight: 600;
         }

         p {
            color: #999;
            font-size: 12px;
         }

         span {
            span {
               margin: 0;
               color: #9baceb;

               line-height: 15px;
            }
         }

         .start-survey {
            text-align: start !important;
         }

         .final-survey {
            text-align: end !important;
         }
      }
   }

   .project-results-snapshot {
      display: block;
      margin: 0 auto;
      border-radius: 8px;
      border: 1px solid #9baceb;
      background: rgba(217, 227, 255, 0.4);
      box-shadow: 0px 4px 12px 0px #a5b1d9;
      width: 100%;
      padding: 30px;
      margin: 50px 0;

      hr {
         background-color: #9baceb;
      }

      h4 {
         color: #252938;
      }

      span {
         font-size: 12px;
         color: #707073;
         margin-left: 10px;
      }

      .result-box {
         margin: 0 auto;
         border-radius: 10px;
         background: #fff;
         box-shadow: 0px 4px 6px 0px #a5b1d9;
         padding: 15px;
         margin-top: 15px;
         height: 90%;
         min-height: 150px;
         max-width: 430px;

         p {
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
         }

         .peopleSurveyed {
            background-color: #fff4bd;
         }

         .currentLink {
            background-color: #ffc397;
         }

         .projectMembers {
            background-color: #a7efde;
         }

         .registeredBeneficiaries {
            background-color: #caf6b0;
         }

         .onGoing {
            background-color: #a6e6ff;
         }

         .activeTargets {
            background-color: #2662ac;
         }

         h2 {
            color: #252938;
            font-size: 46px;
            font-weight: 600;
         }

         .dashboard-circle {
            display: flex;
            flex-direction: column;
            align-items: center;

            .circle {
               margin-top: 10px;
               display: block;
               position: relative;
               background: #ffee94;
               height: 220px;
               width: 220px;
               border-radius: 50%;

               span {
                  position: absolute;
                  top: 50%;
                  right: 50%;
                  transform: translate(50%, -50%);
                  font-size: 32px;
                  font-weight: 600;
                  color: #404040;
               }
            }

            @media (max-width: 1570px) {
               .circle {
                  height: 200px;
                  width: 200px;
               }
            }

            @media (max-width: 1470px) {
               .circle {
                  height: 180px;
                  width: 180px;
               }
            }

            @media (max-width: 1470px) {
               .circle {
                  height: 150px;
                  width: 150px;
               }
            }

            @media (max-width: 1340px) {
               .circle {
                  height: 130px;
                  width: 130px;
               }
            }

            @media (max-width: 1240px) {
               .circle {
                  height: 100px;
                  width: 100px;
               }
            }

            @media (max-width: 1200px) {
               .circle {
                  height: 200px;
                  width: 200px;
               }
            }

            @media (max-width: 450px) {
               .circle {
                  height: 180px;
                  width: 180px;
               }
            }

            @media (max-width: 400px) {
               .circle {
                  height: 140px;
                  width: 140px;
               }
            }
         }

         .chart {
            height: 300px;
            width: 100%;
         }
      }
   }

   .apexcharts-legend-text {
      padding: 20px !important;
   }
   .apexcharts-legend {
      display: flex;
      overflow: auto;
      padding: 0 10px;
      margin-left: 40px;
   }

   .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left {
      margin-left: 40px;
   }

   .apexcharts-legend-series {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px !important;
   }

   .project-information {
      margin: 0 auto;
      margin-bottom: 30px;
      background: #d6deff;
      box-shadow: 0px 4px 12px 0px #a5b1d9;
      padding-bottom: 30px !important;

      .project-information-boxes {
         padding: 15px 20px 0px 15px;
         width: 100%;
         height: 160px;
         border-radius: 10px;
         background: #fff;
         box-shadow: 0px 4px 12px 0px #a5b1d9;
         margin-top: 15px;

         span {
            color: #707073;
            font-size: 16px;
         }

         h2 {
            color: #5770cb;

            font-size: 46px;
         }

         a {
            display: block;
            text-align: end;
            margin-top: 20px;
            text-decoration: underline;
            color: #707073;
            transition: all 0.5 ease !important;

            &:hover {
               text-decoration: underline;
            }
         }
      }
   }
}

.project-timeline {
   padding: 30px;
}

.delete-modal-layout {
   display: flex;
   gap: 10px;
   margin-top: 20px;
   justify-content: center;

   .cancel-button {
      background-color: gray !important;
      height: 40px;
      width: 140px;

      p {
         margin-bottom: 0px !important;
         color: white;
         font-size: 13px;
      }
   }

   .delete-button {
      background-color: #bd0404 !important;
      height: 40px;
      width: 140px;

      p {
         margin-bottom: 0px !important;
         color: white;
         font-size: 13px;
      }
   }
}

.centeredTitle {
   .ant-modal-title {
      text-align: center;
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      align-items: center;
   }
}

.date-picker-container {
   display: flex;
   justify-content: space-between;
   padding: 20px 0px;
   gap: 1rem;
}

.custom-date-picker {
   flex-grow: 1;
   // margin: 0 10px;
}

.submit-buttons {
   display: flex;
   gap: 1rem;
   justify-content: flex-end;
}
