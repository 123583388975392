.showMoreOrLess {
   cursor: pointer;
   width: fit-content;
   padding-top: 12px;
   font-weight: 600;
   color: #5770cb;
   font-size: 14px;

   span {
      padding-left: 8px;
   }
}
