.basic-modal {
   &-close {
      .anticon-close {
         border: none !important;
      }
   }

   &-footer {
      display: none;
   }

   .rejectTask {
      h4 {
         margin-bottom: 20px;
         color: #5770cb;
         font-family: Poppins;
         font-size: 20px;
         font-weight: 600;
         text-align: center;
      }

      span {
         font-size: 15px;
      }

      .formFields {
         span {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
         }
      }
      .modalButtons {
         display: flex;
         justify-content: center;
         gap: 20px;

         button {
            padding: 4px 22px;
            box-shadow: 0 1px 6px 0 rgba(165, 177, 217, 0.77);
            border-radius: 8px;
            height: 40px;
            font-family: Poppins;
         }
         .cancel {
            color: #5770cb;
            background: #fff;
            border: 1px solid #d9e3ff;
            box-shadow: none;
            font-weight: 500;
            display: flex;
            align-items: center;
         }

         .primaryButton {
            border-radius: 8px;
            border: 1px solid #5770cb;
            background: #5770cb;
            color: #fff;

            &:hover {
               color: #fff;
            }
         }
      }
   }

   h2 {
      color: #5770cb;
      font-size: 22px;
      margin-bottom: 16px;
      text-align: center !important;
      font-weight: 600;
   }
   .subscription-notification {
      text-align: center;
      padding: 20px;

      p {
         margin: 10px 0;
         font-size: 16px;
         font-weight: 400 !important;
      }

      .subscription-notification--days {
         color: #ff9a6a;
         font-size: 24px;
         font-weight: bold;
         margin-left: 5px;
         background-color: #fff1e8;
         padding: 5px 15px;
         border-radius: 8px;
      }
   }

   .reactivated-modal {
      text-align: center;

      & h2 {
         margin-top: 16px;
      }

      & p {
         font-size: 14px;
         font-weight: 400 !important;
      }
   }
}

.basic-modal-custom-style {
   .basic-modal-content {
      padding: 32px 40px 40px !important;

      h3 {
         color: #707073 #de8344;
         text-align: center;
         font-family: Poppins;
         font-size: 18px;
         font-weight: 600;
         padding: 0 !important;

         span {
            color: #de8344;
         }
      }

      h4 {
         color: #5770cb;
         text-align: center;
         font-family: Poppins;
         font-size: 18px;
         font-weight: 600;
         padding: 0 !important;
      }

      p {
         padding: 0 !important;
         font-weight: 500;
      }

      .alignText {
         text-align: center;
      }

      .ant-radio-checked {
         .ant-radio-inner {
            background-color: #fff;
            border-color: #5770cb;
         }
      }
      .ant-radio-inner::after {
         background-color: #5770cb;
      }
   }

   .cancel {
      color: #5770cb;
      background: #fff;
      border: 1px solid #4158cf;
      box-shadow: none;
      font-weight: 500;
      display: flex;
      align-items: center;
      width: 100px;
      height: 38px;
      justify-content: center;
   }

   .primaryButton {
      border-radius: 8px;
      border: 1px solid #5770cb;
      background: #5770cb;
      color: #fff;
      min-width: 100px;
      height: 38px;
      justify-content: center;

      &:hover {
         color: #fff !important;
      }
   }
}
