.accountSettings {
   margin: 25px 0;
   background-color: #fff;
   border-radius: 8px;
   padding-bottom: 50px;
   box-shadow: 0px 1px 6px 0px rgba(165, 177, 217, 0.77);

   .title {
      color: #252938;
      font-family: Poppins;
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
   }

   h4 {
      color: #5770cb;
      font-family: Poppins;
      font-size: 15px;
      font-weight: 600;
   }

   .defaultButton {
      color: #5770cb;
      border: 1px solid #5770cb;
      font-weight: 500;
      border-radius: 8px;
      height: 40px;
      font-family: Poppins;
   }
   .primaryButton {
      border-radius: 8px;
      border: 1px solid #5770cb;
      background: #5770cb;
      color: #fff;
      width: auto;
      height: 38px;
      justify-content: center;

      &:hover {
         color: #fff !important;
      }
   }
}

.user-profile-logo {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 120px;
   width: 120px;
   background-color: #252938;
   border-radius: 50%;
   border: 4px solid white;
   cursor: pointer;
   margin: 0 auto;
   overflow: hidden;

   span {
      font-weight: bold;
      color: lightgray;
      font-size: 46px;
   }

   &:hover {
      .delete-icon {
         display: flex;

         p {
            font-weight: 900;
         }
      }
   }

   .delete-icon {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 11px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      gap: 10px;
   }

   .user-profile-logo--icon {
      position: absolute;
      bottom: -25px;
      right: -25px;
   }
}

.user-profile-position {
   position: absolute;
   bottom: 0;
   left: 70px;
   transform: translateY(50%);
   overflow: unset;
}

.profileInformation {
   .userLogo {
      height: 150px;
      background-color: #9baceb;
      position: relative;
      margin: 0 0 100px 0;
      border-radius: 8px 8px 0px 0px;
   }

   .profileContent {
      margin: 0 20px;

      @media only screen and (min-width: 768px) {
         margin: 0 70px;
      }

      @media only screen and (min-width: 1200px) {
         margin: 0 150px 0 70px;
      }
   }

   .personalInformation {
      .required::before {
         content: "* ";
         color: red;
      }

      .label-text {
         font-size: 13px;
         font-weight: 500;
      }

      .react-tel-input {
         .form-control {
            width: 100%;
         }
      }
   }
}

.loginInformation {
   padding: 40px;
}
