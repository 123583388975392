.date-submission-type-wrapper {
   &--date-picker {
      max-width: 304px;
      width: 100%;
      border-color: #999999;
   }

   &--error {
      display: block;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      color: #ff4d4f;
   }
}
