.paymentInformation {
   padding: 40px;

   .currentSubscription {
      padding: 40px 30px;
      margin-top: 40px;
      background-color: #eef2ff;
      border-radius: 8px;
      box-shadow: 0px 1px 6px 0px rgba(165, 177, 217, 0.77);

      h6 {
         color: #5770cb;
         font-size: 22px;
         padding-bottom: 15px;
      }

      .subscriptionPlanBoxes {
         .ant-row:nth-child(2) {
            flex-direction: column-reverse;

            @media only screen and (min-width: 1200px) {
               flex-direction: row-reverse;
            }
         }
         .subscriptionPlans {
            border-radius: 8px;
            border: 2px solid var(--Blue-purple-Purple-2, #9baceb);
            background: var(--Grey-White, #fff);
            text-align: center;
            box-shadow: 0px 4px 12px 0px rgba(165, 177, 217, 0.8);
            transition: all 0.3s ease;
            font-family: "Poppins";

            .planTitle {
               display: flex;
               flex-direction: column;
               align-items: center;
               grid-gap: 10px;
               padding: 26px 20px;
               font-size: 24px;
               color: #5770cb;

               .months {
                  border: 1px solid #9baceb;
                  padding: 6px 8px 6px 8px;
                  border-radius: 20px;
                  width: 125px;
                  font-size: 14px;
                  font-weight: 600;
               }
            }

            .content {
               color: #707073;
            }

            svg {
               color: #9baceb;
            }

            &:focus,
            &.selected {
               border-color: #9baceb;
               box-shadow: 0px 4px 12px 0px rgba(165, 177, 217, 0.8);
               transform: scale(1.03);

               .planTitle {
                  background-color: #eef2ff;
                  border: 1px solid #eef2ff;
                  border-radius: 8px 8px 0px 0px;
               }

               .months {
                  color: #5770cb;
                  border: 1px solid #d9e3ff;
                  background-color: #d9e3ff;
               }

               .currentPlan {
                  height: 35px;
                  background-color: #f5f5f5;
                  color: #00000040;
                  border-color: #d9d9d9;
               }

               .content {
                  p:nth-child(1) {
                     color: #f58f45;
                  }
               }

               .payment {
                  .annuallyPrice {
                     color: #f58f45;
                  }
               }

               .plan-details-modal {
                  span {
                     color: #f58f45 !important;
                  }
               }
            }

            .payment {
               font-size: 34px;
               font-weight: 600;
               margin-bottom: 2rem;

               span {
                  font-size: 14px;
                  color: #999999;
               }

               .annuallyPrice {
                  font-size: 40px;
                  color: #999999;
                  font-weight: 700;
               }
            }

            .defaultButton {
               height: 35px;
               margin: 20px;
               background-color: #eef2ff;
            }

            .plan-details-modal {
               span {
                  color: #5770cb !important;
                  text-decoration: underline;
                  cursor: pointer;
               }
            }
         }

         .columnPosition {
            display: flex;
            justify-content: center;
            @media only screen and (min-width: 1200px) {
               justify-content: flex-end;
            }

            .ant-radio-checked {
               .ant-radio-inner {
                  border-color: #5770cb;
                  background-color: #ffffff;
               }
               .ant-radio-inner::after {
                  background-color: #5770cb;
               }
            }
         }
      }

      @media only screen and (min-width: 1200px) {
         padding: 40px 80px;
      }
   }
   .cancelSubscription {
      display: flex;
      justify-content: flex-end;
   }

   .paymentMethod {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 30px;
      gap: 40px;

      p {
         color: #5770cb;
         font-family: Poppins;
         font-size: 18px;
         font-weight: 500;
      }

      .nextPayment {
         display: flex;
         flex-direction: column;
         justify-content: center;
         border-radius: 8px;
         border: 1px solid #9baceb;
         background: #eef2ff;
         box-shadow: 0px 1px 6px 0px rgba(165, 177, 217, 0.77);
         text-align: center;
         min-width: 210px;
         padding: 10px 16px;

         h3 {
            color: #5770cb;
            font-family: Poppins;
            font-size: 26px;
            font-weight: 600;
         }
      }

      .editPaymentMethod {
         width: 100%;
         border-radius: 8px;
         box-shadow: 0px 1px 6px 0px rgba(165, 177, 217, 0.77);
         padding: 10px 16px;

         .cardInformation {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 20px;

            .infoDetails {
               display: flex;
               align-items: center;

               .creditCardIcon {
                  width: fit-content;
                  font-size: 33px;
                  padding: 0 18px;
                  border-radius: 6px;
                  box-shadow: 0px 3px 12px 0px rgba(128, 128, 128, 0.35);

                  svg {
                     border-radius: 8px;
                     path {
                        fill: #5770cb;
                     }
                  }
               }

               .visaEnding {
                  display: flex;
                  flex-direction: column;
                  margin-left: 20px;
                  font-family: "Poppins";

                  span:nth-child(1) {
                     font-weight: 600;
                  }

                  span:nth-child(2) {
                     color: #999;
                  }
               }
            }
            @media only screen and (min-width: 576px) {
               flex-direction: row;
            }
         }
      }
      .defaultButton {
         background-color: #eef2ff;
         width: fit-content;
      }

      @media only screen and (min-width: 1200px) {
         flex-wrap: nowrap;
      }
   }
}

.billingHistory {
   padding: 40px;

   .ant-pagination-item,
   .ant-pagination-next,
   .ant-pagination-prev {
      border: 1px solid #d9d9d9;
   }
   .ant-pagination-item-active {
      color: #5770cb;
      border-color: #5770cb;
      background-color: #d9e3ff;
   }

   .ant-table-cell-fix-right {
      box-shadow: 0px 1px 6px 0px rgba(165, 177, 217, 0.77);
   }
}
