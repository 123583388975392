.collectionEnumerators {
   background-color: #ffffff;
   padding: 20px;

   .collectionEnumerators-tab-buttons_container {
      .deleted-button-wrapper {
         margin-left: 40px;
      }
      @media (max-width: 576px) {
         flex-direction: column;
         margin-bottom: 10px;

         .deleted-button-wrapper {
            margin-left: 0;
         }
      }
   }

   .box {
      height: 240px;
      border-radius: 10px;
      border: 1.5px solid var(--Blue-purple-Purple-3, #d9e3ff);
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }

   .averageTasks {
      h3 {
         color: #28c76f;
         font-family: Poppins;
         font-size: 90px;
         font-style: normal;
         font-weight: 600;
         margin-top: 60px;
      }

      p {
         color: #999;
         font-size: 14px;
         font-style: normal;
         font-weight: 500;
         font-family: Poppins;
      }
   }

   .titleSection {
      padding: 80px 0;
      display: flex;
      align-items: center;

      p {
         color: var(--Blue-purple-Dark-purple, #252938);
         font-family: Poppins;
         font-size: 20px;
         font-style: normal;
         font-weight: 600;
         line-height: 26px;
         margin-bottom: 0;
      }
      .createTaskButton {
         display: flex;
         justify-content: flex-end;

         button {
            border-radius: 8px;
            background: var(--blue-purple-purple-1, #5770cb);
            box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
            display: flex;
            height: 44px;
            padding: 4px 16px;
            justify-content: center;
            align-items: center;
            color: var(--character-primary-inverse, #fff);
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;

            &:hover {
               color: #fff;
            }
         }
         @media (max-width: 576px) {
            justify-content: center;
         }
      }

      @media (max-width: 576px) {
         text-align: center;
         gap: 20px;
      }
   }

   .ant-pagination-item,
   .ant-pagination-next,
   .ant-pagination-prev {
      border: 1px solid #d9d9d9;
   }
   .ant-pagination-item-active {
      color: #5770cb;
      border-color: #5770cb;
      background-color: #d9e3ff;
   }

   .ant-tabs {
      margin: 0;
   }
}

.stepComponents {
   padding: 35px;
   border-radius: 0px 0px 8px 8px;
   background: #fff;
   box-shadow: 0px 4px 12px 0px rgba(59, 75, 104, 0.25);
   margin-bottom: 20px;

   .returnToPrevState {
      width: fit-content;
      color: var(--Blue-purple-Purple-2, #9baceb);
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      // margin: 35px 0;

      span {
         margin-right: 10px;
      }
   }
   .childrenComponents {
      // display: flex;
      margin-top: 40px;
      // padding: 40px 0;

      .createTaskForm {
         // margin-top: 40px;

         .formFields {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 8px;
         }

         .roundNameInput,
         .surveyToolInput {
            width: 50%;

            @media (max-width: 576px) {
               width: 100%;
            }
         }

         .deadlineInput {
            width: 40%;
            @media (max-width: 576px) {
               width: 100%;
            }
         }

         .roundNameInput {
            .include {
               color: var(--Greys-Mid-Gray, #999);
               font-weight: 400;
               padding-bottom: 16px;
            }
         }

         p,
         .include {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 2px;
         }
         p {
            color: var(--Blue-purple-Dark-purple, #252938);
         }

         .interviewMethod {
            p {
               margin: 20px 0 16px;
            }
            .ant-radio-group {
               width: 100%;

               .ant-row {
                  display: flex;
                  gap: 30px;
               }
            }
            label {
               display: flex;
               padding: 16px;
               flex-direction: column;
               align-items: flex-start;
               flex: 1 0 0;
               border-radius: 16px;
               background: var(--Greys-White, #fff);
               box-shadow: 0px 1px 6px 0px rgba(165, 177, 217, 0.77);

               .ant-radio {
                  align-self: self-start;
                  padding-left: 10px;
                  margin-top: 0;
               }

               h6 {
                  color: #4158cf;
                  font-family: Poppins;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
                  padding-left: 20px;
               }
            }
            span {
               display: flex;
               margin-top: 10px;

               .anticon {
                  font-size: 40px;
                  color: #5770cb;
               }
            }
         }
         .continueButton {
            text-align: center;
            button {
               width: 200px;
               color: white !important;
               font-weight: 500;
               height: 44px;
               border-radius: 8px;
               background-color: #5770cb;
            }
         }
         @media (max-width: 576px) {
            width: 100% !important;
         }
      }

      .taskParagraph {
         color: var(--Neutrals-Gray, #555);
         font-size: 14px;
         font-weight: 500;
         line-height: 22px;
         padding-top: 5px;
         margin-bottom: 0;
      }

      .selectLocation {
         .locationTableData {
            .antTableData {
               tr td:nth-child(1) {
                  border-bottom: 1px solid #f0f0f0;
               }
               .actionColumn {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 30px;
                  width: 30px;
                  color: #5770cb;
                  border-color: #d9e3ff;
                  border-radius: 8px;
                  background-color: #d9e3ff;
                  margin-right: 10px;
                  font-size: 16px;

                  &:hover {
                     background-color: #5770cb;
                     color: #d9e3ff;
                     transition: 0.3s ease-in-out;
                  }
               }
            }

            .excelDragger {
               width: 80%;
               margin: 0 auto;
               max-width: 600px;
               text-align: center;

               //mos me e bo push pa e bo mir qet pjesen e dizajnit, mos me shtu kaq shum rreshta per style t tekstit
               h5 {
                  color: var(--Blue-purple-Purple-1, #5770cb);
                  font-family: Poppins;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 144%;
               }

               .multipleLocation {
                  color: var(--greys-dark-gray-25, #404040);
                  text-align: center;
                  font-family: Poppins;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px;
               }

               .downloadFormat {
                  color: var(--Blue-purple-Purple-1, #5770cb);
                  text-align: center;
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 15px;
                  border-bottom: 1px solid var(--Blue-purple-Purple-1, #5770cb);
                  width: fit-content;
                  margin: 20px auto;
               }

               .uploadFileText {
                  color: var(--Primary-Main-Blue, #4158cf);
                  text-align: center;
                  font-family: Poppins;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
               }

               span {
                  color: var(--Neutrals-Gray-400, #777);
                  text-align: center;
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
               }
            }
         }
      }

      .selectEnumerators {
         .notesTextarea {
            margin-top: 20px;

            p {
               font-weight: 500;
               color: #707073;
            }
         }
      }

      .confirmTeam {
         .antTableData {
            margin-top: 32px;
         }
      }

      .ant-pagination-item,
      .ant-pagination-next,
      .ant-pagination-prev {
         border: 1px solid #d9d9d9;
      }
      .ant-pagination-item-active {
         color: #5770cb;
         border-color: #5770cb;
         background-color: #d9e3ff;
      }

      .miniBanner {
         display: flex;
         align-items: center;
         gap: 12px;
         padding: 14px 16px;
         border-radius: 6px;
         margin: 24px 0 36px 0;
         min-height: 65px;

         h5 {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
         }
      }

      .locationNameBanner {
         border: #9baceb;
         background: #eef2ff;
         h5 {
            color: #5770cb;
         }
      }

      .surveysBanner {
         // border: 1px solid var(--Blue-purple-Purple-2, #9baceb);
         background: #fff1e8;
         justify-content: center;
         margin-bottom: 20px;
         h5 {
            font-weight: 400;
            color: #f58f45;
         }
      }

      .confirmTeamBanner {
         margin-top: 40px;
         min-height: 50px;
      }
      .selectEnumeratorsButtons {
         display: flex;
         justify-content: center;
         gap: 30px;
         flex-wrap: wrap;

         button {
            padding: 4px 22px;
            box-shadow: 0px 1px 6px 0px rgba(165, 177, 217, 0.77);
            border-radius: 8px;
            height: 40px;
            font-family: Poppins;
         }

         .cancel {
            border: 1px solid #d9e3ff;
            background: #eef2ff;
         }

         .preview {
            border-radius: 8px;
            border: 1px solid #5770cb;
            background: #5770cb;
            color: #fff;
         }

         .goBack {
            color: #5770cb;
            background: #fff;
            border: 1px solid #d9e3ff;
            box-shadow: none;
            font-weight: 500;
            display: flex;
            align-items: center;
         }
      }

      .selectTeamButtons {
         margin-top: 20px;
      }

      .taskCreatedPage {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: space-between;
         max-width: 650px;
         margin: 0 auto 40px auto;
         min-height: 250px;
         padding: 26px 16px;
         gap: 20px;
         background-color: rgb(238, 242, 255);
         border: 2px solid rgb(179, 188, 236);
         border-radius: 8px;

         .title {
            font-size: 20px;
            text-align: center;
            margin-bottom: 25px;
         }
      }
   }

   .title {
      color: var(--Blue-purple-Purple-1, #5770cb);
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 144%;
      display: flex;
      gap: 8px;
      // margin: 20px 0 40px;

      .taskText {
         margin-bottom: 0;
         font-size: 20px;
         font-weight: 600;
      }
   }
}

.createLocationModal {
   .createLocationButtons {
      text-align: center;
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      button {
         font-weight: 500;
         height: 44px;
         border-radius: 8px;
      }

      .cancel {
         color: #5770cb;
         border: none;
         box-shadow: none;
      }

      .createLocation {
         color: #fff !important;
         background-color: #5770cb;
         width: 200px;
      }
   }

   .ant-modal-header {
      margin-bottom: 0;
      .ant-modal-title {
         color: #4158cf;
         font-family: Poppins;
         font-size: 18px;
         font-weight: 500;
      }
   }
   .ant-modal-content {
      padding: 8% !important;
   }

   .ant-modal-body {
      span {
         font-size: 14px;
         font-style: normal;
         font-weight: 500;
      }

      .formFields {
         .ant-form-item-control-input-content {
            margin-top: 8px;
         }

         .requiredField::before {
            display: inline-block;
            margin-inline-end: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: "*";
         }
      }
   }
}

.rangePicker {
   .ant-picker-panels {
      @media (max-width: 576px) {
         flex-direction: column;
      }
   }
}

.taskManagementFilters {
   margin-top: 30px;
   width: 100%;

   .ant-select {
      width: 100%;
   }

   .label-text {
      font-weight: 500;
      color: #3a3737;
   }

   .deadline {
      display: flex;
      flex-direction: column;
   }
}
