.date_time-submission-type-wrapper {
   &--date-picker,
   &--time-picker {
      max-width: 304px;
      width: 100%;
      border-color: #999999;
   }

   &--date-picker {
      margin-bottom: 32px;
   }

   &--error {
      display: block;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      color: #ff4d4f;
   }

   @media (min-width: 680px) {
      &--date-picker {
         margin-right: 32px;
      }
   }
   @media (min-width: 1280px) {
      &--date-picker {
         margin-bottom: 0;
      }
   }
}
