// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";
@import "./app/assets/styles/global.scss";
// Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";
// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";
// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }
// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";
// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }
.example-highlight > pre {
   background: none transparent !important;
   margin: 0 !important;
   padding-top: 0.5rem;
   code {
      overflow-y: auto;
      display: block;

      span {
         background: none transparent !important;
      }
   }
}

.ant-form-item .ant-form-item-label {
   display: inline-block;
}
.json > pre {
   background: none #fff !important;
}

.example-code > .json {
   background: none #fff !important;
}

.symbol.symbol-45 > svg {
   width: 100%;
   max-width: 45px;
   height: 45px;
}

.react-bootstrap-table {
   overflow-x: auto;
}

.react-bootstrap-table {
   th {
      outline: none;

      &.sortable {
         .svg-icon-sort {
            opacity: 0;
         }

         &:hover {
            cursor: pointer;

            .svg-icon-sort {
               opacity: 1;
            }
         }
      }
   }

   .table.table-head-custom thead tr {
      .sortable-active {
         color: #1bc5bd !important;
      }
   }
}

.cursor-default {
   cursor: default !important;
}

.display-block {
   display: block;
}

.display-flex {
   display: flex;
}

.display-center {
   display: flex;
   justify-content: center;
   align-items: center;
}

.border {
   border: 1px solid red !important;
}

.w-full {
   width: 100%;
}

.h-full {
   height: 100%;
}

.truncate {
   &-1,
   &-2,
   &-3,
   &-4 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
   }

   &-1 {
      -webkit-line-clamp: 1;
      line-clamp: 1;
   }
   &-2 {
      -webkit-line-clamp: 2;
      line-clamp: 2;
   }
   &-3 {
      -webkit-line-clamp: 3;
      line-clamp: 3;
   }
   &-4 {
      -webkit-line-clamp: 4;
      line-clamp: 4;
   }
}

::-webkit-scrollbar {
   width: 6px;
   background: #576596;
}

::-webkit-scrollbar-track {
   background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
   background: #576596;
}

::-webkit-scrollbar-thumb:hover {
   background: #555;
}

::-webkit-scrollbar-vertical {
   width: 8px;
}

::-webkit-scrollbar-track-vertical {
   background: #576596;
}

::-webkit-scrollbar-thumb-vertical {
   background: #576596;
}

::-webkit-scrollbar-thumb-vertical:hover {
   background: #555;
}
