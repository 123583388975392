.textarea-submission-type-wrapper {
   &--textarea {
      border-color: #999999;
   }
   &--error {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      color: #ff4d4f;
   }
}
