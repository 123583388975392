.file-submission-type-wrapper {
   &--input {
      max-width: 640px;
      width: 100%;

      &-list {
         margin-top: 1rem;

         .custom-file-item-renderer {
            padding: 12px 16px;
            border: 1px solid #d9e3ff;
            border-radius: 8px;
            background: #eef2ff;
            margin-bottom: 4px;

            &_icon {
               width: 44px;
               height: 44px;
               border-radius: 50%;
               background: #d9e3ff;
               flex-shrink: 0;
            }
            &_content {
               width: 100%;

               &--filename {
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 18px;
                  color: #3a3737;
                  transition: color 0.2s ease-in-out;

                  &.filename_link:hover {
                     color: #4158cf;
                     cursor: pointer;
                  }
               }
               &--filesize {
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 18px;
                  color: #707073;
                  margin-top: 2px;
               }
            }
            &_progress {
               width: 100%;

               &-wrapper {
                  width: 100%;
                  height: 8px;
                  border-radius: 4px;
                  background: #d5d5d5;
                  position: relative;

                  &_inner {
                     height: 100%;
                     border-radius: 4px;
                     background: #5770cb;
                     position: absolute;
                     inset: 0;
                  }
               }

               &-percentage {
                  font-family: Poppins;
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 24px;
                  text-align: right;
                  color: #5770cb;
               }
            }
         }
      }

      .drag-error {
         & > div {
            border-color: #ff4d4f !important;
            background: #ff4d4f20 !important;
         }
      }

      &-drag {
         border: 2px dashed #9baceb !important;
         background: #eef2ff !important;

         &:hover {
            border-color: #4096ff !important;
         }

         & > span {
            padding: 32px 16px !important;
         }
         &-container {
            .ant-upload {
               font-family: Poppins;
               font-weight: 600;
               line-height: 24px;
               text-align: center;

               &-drag-icon {
                  width: 44px;
                  height: 44px;
                  border-radius: 50%;
                  background-color: #fff;
                  display: flex;
                  justify-content: center;
                  margin: 0 auto 10px auto;
                  color: #4158cf;
               }
               &-text {
                  font-family: Poppins;
                  font-weight: 600;
                  line-height: 24px;
                  text-align: center;
                  font-size: 16px;
                  color: #4158cf;

                  & > span {
                     text-decoration: underline;
                  }
               }
               &-hint {
                  font-family: Poppins;
                  font-weight: 600;
                  line-height: 24px;
                  text-align: center;
                  font-size: 14px;
                  color: #777777;

                  &-lite {
                     font-family: Poppins;
                     font-weight: 400;
                     line-height: 24px;
                     text-align: center;
                     font-size: 14px;
                     color: #777777;
                  }
               }
            }
         }
      }
   }
   &--error {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      color: #ff4d4f;
   }
}
