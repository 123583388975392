.preview-modal {
   &-close {
      border: 1px solid red;
      &-x {
         .anticon-close {
            border: none !important;
            outline: none !important;
         }
      }
   }

   &-body {
      margin: 68px 6px 32px 6px;

      .preview-modal--title {
         font-family: Poppins;
         font-size: 22px;
         font-weight: 600;
         line-height: 31.68px;
         text-align: left;
         color: #5770cb;
      }
      .preview-modal--content {
         margin-top: 32px;
      }
   }

   &-footer {
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
   }
}

@media (min-width: 640px) {
   .preview-modal {
      &-body {
         margin: 68px 16px 32px 16px;
      }

      &-footer {
         margin-bottom: 36px;
      }
   }
}
