
.example-highlight > pre {
   background: none transparent !important;
   margin: 0 !important;
   padding-top: 0.5rem;
   code {
      overflow-y: auto;
      display: block;

      span {
         background: none transparent !important;
      }
   }
}

.json > pre {
   background: none #fff !important;
}

.example-code > .json {
   background: none #fff !important;
}

.symbol.symbol-45 > svg {
   width: 100%;
   max-width: 45px;
   height: 45px;
}

.react-bootstrap-table {
   overflow-x: auto;
}

.react-bootstrap-table {
   th {
      outline: none;

      &.sortable {
         .svg-icon-sort {
            opacity: 0;
         }

         &:hover {
            cursor: pointer;

            .svg-icon-sort {
               opacity: 1;
            }
         }
      }
   }

   .table.table-head-custom thead tr {
      .sortable-active {
         color: #1bc5bd !important;
      }
   }
}

.custom-notification-notice-icon {
   position: static;
   max-width: 88px;
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 20px 24px 20px 24px;
   border-radius: 8px 0px 0px 8px;
}

.custom-notification-notice-message {
   padding-top: 24px;
   font-family: Poppins;
   font-size: 18px;
   font-weight: 600;
   line-height: 26px;
   letter-spacing: 0em;
   margin-inline-start: calc(88px + 24px) !important;
}

.custom-notification-notice-description {
   padding-bottom: 24px;
   padding-right: 24px;
   font-family: Lato;
   font-size: 16px;
   font-weight: 400;
   line-height: 23px;
   letter-spacing: 0em;
   text-align: left;
   color: #3a3737 !important;
   margin-inline-start: calc(88px + 24px) !important;
}

.custom-notification-notice-success {
   .custom-notification-notice-icon {
      background: #f2fefe;
   }

   .custom-notification-notice-message {
      color: #0e9349 !important;
   }
}

.custom-notification-notice-error {
   .custom-notification-notice-icon {
      background: #fdedef;
   }

   .custom-notification-notice-message {
      color: #e31a32 !important;
   }
}

.cursor-default {
   cursor: default !important;
}

.display-block {
   display: block;
}

.outline {
   outline: 1px solid red;
   &-all {
      * {
         outline: 1px solid red;
      }
   }
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
   color: #003daa;
}

.ant-tabs .ant-tabs-tab {
   color: #777;
}

.ant-tabs-ink-bar {
   border-bottom: 2px solid #003daa;
}

.ant-modal .ant-modal-content {
   padding: 32px 40px;
}

@media (max-width: 991px) {
   .ant-modal .ant-modal-content {
      width: 100%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
   }
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
   padding-left: 0px;
}

.ant-modal-content ul {
   letter-spacing: 0px !important;
}
