.note-submission-type-wrapper {
   padding: 16px 0;

   &--note_text {
      .marked {
         p {
            font-family: Poppins !important;
            font-size: 16px !important;
            font-style: italic !important;
            font-weight: 500 !important;
            line-height: 23.04px !important;
            letter-spacing: 0.01em !important;
            color: #999999 !important;
            margin: 0 !important;
            margin-bottom: 6px !important;
         }
      }

      font-family: Poppins;
      font-size: 16px;
      font-style: italic;
      font-weight: 500;
      line-height: 23.04px;
      letter-spacing: 0.01em;
      color: #999999;
      margin: 0 !important;
      margin-bottom: 6px !important;
   }

   &--hint {
      font-family: Poppins;
      font-size: 13px;
      font-weight: 400;
      line-height: 18.83px;
      color: #707073;
   }

   &--read_only_input {
      margin-top: 14px;
      max-width: 504px;
      width: 100%;
   }
}
