.organization-item {
   padding: 7px !important;
}

.result-list {
   cursor: pointer;
   position: relative;
   width: 100%;
   height: 100%;
   padding: 10px 15px;
   background: var(--kaporg-result-bg);
   box-shadow: 2.4px 1px 3.3743591309px 0px #00000040;
   border-radius: 0px 8.4359px 8.4359px 0px;
   .information-part {
      display: flex;
      .information-wrapper {
         width: 100%;
      }
      .menu-icon {
         width: 30px;
         height: 30px;
      }
      .result-title {
         margin-top: 8px;
         font-family: "Poppins";
         font-style: normal;
         font-weight: 400;
         font-size: 16.8718px;
         line-height: 18px;
         letter-spacing: 0.01em;
         text-transform: capitalize;
         color: #036051;
      }
      .social-part {
         display: flex;
         width: 100%;
         justify-content: space-between;
         span {
            margin-right: 9px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 275;
            font-size: 10px;
            line-height: 10px;
            letter-spacing: 0.01em;
            color: var(--kaporg-gray-3);
         }
      }
   }

   .info-w-icon {
      display: flex;
      align-items: center;
      .icon-text {
         max-width: 70px;
         text-overflow: ellipsis;
         overflow: hidden;
         white-space: nowrap;
      }
   }
   .icon {
      pointer-events: none;
      margin-left: 0;
      margin-right: 3px;
   }

   .line {
      width: 100%;
      height: 0px;
      margin: 10px 0;
      border: 0.84359px solid #d7d7d7;
   }
   .assessment-result-section {
      display: flex;
      justify-content: space-between;
      span {
         font-family: "Poppins";
         font-style: normal;
         font-weight: 275;
         font-size: 10.9667px;
         line-height: 16px;
         letter-spacing: 0.01em;
         color: var(--kaporg-gray-3);
         display: flex;
         align-items: baseline;
      }
   }

   .select-circle {
      position: absolute;
      right: 8px;
      top: 2px;
   }
}

.organization-search-bar {
   .bottom-buttons {
      margin-top: 10px;
      border-color: var(--kaporg-dark-green);
      button {
         border-color: var(--kaporg-dark-green);
         width: 100%;
         background-color: rgba(239, 239, 239, 0.539);
         font-family: "Poppins";
         font-style: normal;
         font-weight: 275;
         font-size: 10.9667px;
         line-height: 16px;
         letter-spacing: 0.01em;
         color: var(--kaporg-dark-green);
         display: flex;
         align-items: center;
         justify-content: center;
         i {
            color: var(--kaporg-dark-green);
         }
      }
   }
}
