.marked {
   p {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 23.04px;
      letter-spacing: 0.01em;
      color: #252938;
   }
}

.quick-action-section {
   border-radius: 8px;
   background: #fff;
   box-shadow: 0px 4px 12px 0px #a5b1d9;
   margin-top: 2%;
   display: flex;
   flex-direction: column;
   padding: 40px 15px 60px 15px;

   &--header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      text-align: center;

      h5 {
         color: #252938;
         font-weight: 600;
         font-size: 22px;
      }

      p {
         color: #9baceb;
         font-weight: 500;
         font-size: 14px;
      }
   }

   .tools-section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 30px;
   }
}
