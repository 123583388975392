* {
   // scroll-behavior: smooth;
}

p.no-data {
   margin: 15px 5px;
   color: var(--kaporg-black-color);
}

.disabled {
   pointer-events: none;
   opacity: 0.5;
}

.clickable {
   cursor: pointer;
}

.wide {
   .modal-dialog {
      max-width: 100%;

      @media (min-width: 1300px) {
         max-width: 1100px;
      }
   }
}

.dark-mode {
   .dropzone-wrapper {
      background-color: var(--kaporg-dark-green);
   }

   .MuiTabs-flexContainer {
      background-color: var(--kaporg-dark-black);
      color: var(--kaporg-black-text);
   }

   .skeleton-loader rect {
      fill: var(--kaporg-dark-black) !important;
   }

   .list-group-item {
      background-color: var(--kaporg-input-color) !important;
      color: var(--kaporg-gray-3);
   }

   .form-group input,
   .form-group span {
      background-color: var(--kaporg-input-color) !important;
      color: var(--kaporg-black-text);
   }

   .css-1rhbuit-multiValue {
      background-color: var(--kaporg-input-border-background);
   }

   .css-1uccc91-singleValue {
      color: var(--kaporg-black-text);
   }

   .react-datepicker-wrapper input {
      background-color: var(--kaporg-input-color) !important;
      color: var(--kaporg-white-color) !important;
   }

   .submit-textarea {
      background-color: var(--kaporg-input-color) !important;
      color: var(--kaporg-white-color);
   }

   .MuiMenu-list {
      background-color: var(--kaporg-popup-background) !important;
      color: var(--kaporg-dark-green) !important;
   }

   .css-yk16xz-control {
      background-color: var(--kaporg-black) !important;
   }

   .css-1pahdxg-control {
      background-color: var(--kaporg-black) !important;
   }

   .no-data-message {
      color: var(--kaporg-black-text);
   }

   .css-26l3qy-menu {
      background-color: var(--kaporg-black-color);
   }

   .form-group > div:first-of-type > div:first-of-type {
      background-color: var(--kaporg-input-color) !important;
   }

   .MuiTypography-colorTextSecondary {
      color: white;
   }

   .MuiFormControl-root {
      background-color: var(--kaporg-dark-green) !important;
   }

   .MuiPaper-root {
      background-color: var(--kaporg-dark-green);
      color: white;
   }

   .form-group > div > div > div > div {
      color: white !important;
   }
}

.sidebar-minimize .aside-menu .menu-nav > .menu-item .menu-submenu .menu-subnav {
   display: none;
}

//antd global styles

.ant-dropdown .ant-dropdown-menu {
   max-height: 290px;
   overflow: auto;
   columns: 2;
   -webkit-columns: 2;
   -moz-columns: 2;

}

.workspace-dropdown.ant-dropdown .ant-dropdown-menu {
    display: flex;
    flex-direction: column;
}
.workspace-dropdown .targets-page {
    .saved-list {
      .ant-dropdown .ant-dropdown-menu {
          max-height: auto !important;
          overflow: auto !important;
          columns: 1 !important;
          -webkit-columns: 1 !important;
          -moz-columns: 1 !important;
      }
    }
}

.ant-checkbox-checked .ant-checkbox-inner {
   border-color: #9baceb;
   background-color: #9baceb;
}

.ant-checkbox-wrapper:hover {
   .ant-checkbox-input {
      border-color: #9baceb;
      background-color: #9baceb;
   }
}

.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
   background-color: #9baceb;
   border-color: #9baceb;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
   border-color: #9baceb;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
   .ant-checkbox-checked:not(.ant-checkbox-disabled)
   .ant-checkbox-inner {
   background-color: #9baceb;
   border-color: #9baceb;
}

.ant-checkbox-checked:not(.ant-checkbox-disabled):after {
   border: none;
}

.menu-section .menu-text {
   color: #f58f45 !important;
}

.arabic-survey {
   direction: rtl;
   unicode-bidi: embed;
}

.kapSurveyPrimaryButton {
   display: inline-flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   flex-shrink: 0;
   border-radius: 8px;
   background: var(--Blue-purple-Purple-1, #5770CB) !important;

   box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1) !important;
   color: white !important;
   font-weight: 500;
}

.kapSurveyPrimaryButton:hover:not(:disabled) {
   background: var(--Blue-purple-Purple-2, #9baceb !important);
   box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
   color: var(--Greys-White, #fff);
   border: none !important;
}

.kapSurveyPrimaryButton:disabled {
   display: inline-flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   flex-shrink: 0;
   border-radius: 8px;
   background: var(--Greys-Light-Gray-1, #f2f2f2) !important;
   color: var(--Greys-Medium-Gray-3, #bfbfbf) !important;
   cursor: not-allowed;
}
@media screen and (max-width: 767px) {
   .kapSurveyPrimaryButton,
   .org-mng-cancel-button {
      margin-top: 10px; /* Adjust the value based on your preference */
   }
}
