.preview-survey-section {
   box-shadow: 0px 4px 12px 0px #a5b1d940;
   background: #ffffff;

   &--header {
      background: #eef2ff;

      &--repeat {
         display: flex;
         justify-content: space-between;
         flex-direction: column;
         background: none;
         padding: 20px 40px;
         align-items: baseline;
         gap: 30px;

         h5 {
            font-family: Poppins;
            font-size: 17px;
            font-weight: 600;
            line-height: 25.92px;
            letter-spacing: 0.01em;
            color: #5770cb;
         }
      }

      h5 {
         font-family: Poppins;
         font-size: 18px;
         font-weight: 600;
         line-height: 25.92px;
         letter-spacing: 0.01em;
         color: #5770cb;
      }
   }

   &--repeat {
      border-radius: 8px;
      background: #f6f8ff;
      // margin: 40px 40px 80px 40px;
      margin: 40px 15px;
   }

   &--questions_wrapper {
      position: relative;
      padding: 0 16px 30px 16px;

      &--repeat {
         background: #eef2ff;
         padding: 30px 16px;
         margin: 0 16px 30px 16px;
         border-radius: 8px;
         box-shadow: none;

         .iteration-indicator {
            margin: 20px 10px 14px 10px;
            color: #252938;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.2px;
            border-bottom: 1px solid #9baceb;
         }
      }

      &:first-child {
         padding-top: 30px;
         .iteration-indicator {
            top: 48px;
         }
      }

      &:empty {
         padding: 0;
      }

      &.extra-padding {
         padding-top: 30px;
      }
   }

   &--header {
      padding: 30px 16px 24px 16px;
   }

   .hideRepeatedGroup {
      display: none;
   }

   .hasRepeatGroupShown {
      padding: 20px 40px 60px 40px;
   }

   .bottomSpace {
      padding-bottom: 10px;
   }

   @media (min-width: 640px) {
      &--repeat {
         margin: 40px 40px 80px 40px;
      }

      &--header {
         &--repeat {
            flex-direction: row;
         }
      }

      &--questions_wrapper {
         padding: 0 40px 40px 40px;
         // padding: 40px;

         &--repeat {
            padding: 30px;
            margin: 0px 30px 30px 30px;

            &:first-child {
               margin-top: -40px;
            }
         }

         &:first-child {
            padding-top: 40px;
         }

         &.extra-padding {
            padding-top: 40px;
         }
      }
      &--header {
         padding: 40px 40px 34px 40px;
      }
   }
}
