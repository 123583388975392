.acknowledge-submission-type {
   &--options {
      &---checkbox {
         &-group {
            display: flex;
            flex-direction: column;
            gap: 8px;
         }
         &-wrapper {
            padding: 8px 14px;
            border: 1px dashed transparent;
            border-radius: 6px;
            display: block;
            margin-inline: 0;
            display: flex;
            align-items: center;
            gap: 4px;

            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0.02em;
            color: #7c7c80;

            &:hover {
               border: 1px dashed #d9d9d9;
            }

            &-checked {
               border: 1px solid #9baceb;
               &:hover {
                  border: 1px solid #9baceb;
               }
            }
         }
         &-inner {
            width: 20px !important;
            height: 20px !important;
            border-radius: 0 !important;
         }

         &-checked {
            span {
               background-color: #5770cb !important;
               // border-color: #5770cb !important;
            }
         }
      }
   }

   &--error {
      display: block;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      color: #ff4d4f;
   }
}
