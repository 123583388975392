.information {
padding: 20px;
max-width: 90%;

}

.background {
    width: 90%;
    margin-left: 30px;
}

.org-mng-heading {
    color: var(--Blue-purple-Purple-1, #5770CB);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px;
}

.paragraph-heading {
    color: var(--Greys-Mid-Gray, #999);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 133.333% */
}

.paragraph-text {
color: var(--Blue-purple-Dark-purple, #252938);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 128.571% */
}

@media (max-width: 768px) {

      .col {
        width: 100%;
        padding: 15px;
        margin-bottom: 25px;
      }
    }

.modal-heading {
    color: var(--Blue-purple-Dark-purple, #252938);
font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 85.714% */
letter-spacing: -0.28px;
padding: 10px;
}


.form-heading {
    color: var(--Blue-purple-Purple-1, #5770CB) !important;
font-family: Poppins !important;
font-size: 16px !important;
font-style: normal !important;
font-weight: 600 !important; 
line-height: 24px !important;

margin-top: 20px;
margin-bottom: 20px;
}

.label-text {
    color: var(--Blue-purple-Dark-purple, #252938);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
margin-top: 5px;
}

.org-mng-cancel-button {
    display: flex;
width: 138px;
padding: 8px 28px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--Blue-purple-Purple-1, #5770CB);
box-shadow: 0px 3px 5px 0px rgba(128, 128, 128, 0.35);
color: var(--Blue-purple-Purple-1, #5770CB);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
letter-spacing: -0.14px;
}
.login-information-email {
    color: var(--Greys-Dark-gray, #707073);
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 18px;
margin: 10px 0px 10px 0px ;
}
.password-list-text {
    color: var(--Greys-Dark-gray, #707073);
/* 12pt Body */
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 150% */
}