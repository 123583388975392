.preview-question {
   margin-bottom: 14px;
   &-label {
      display: flex;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 23.04px;
      letter-spacing: 0.01em;
      color: #252938;

      .marked {
         p {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 23.04px;
            letter-spacing: 0.01em;
            color: #252938;
         }
      }

      &.required::before {
         content: "*";
         color: #ff4d4f;
         margin-right: 4px;
      }
   }

   &-hint {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.83px;
      color: #707073;

      .marked {
         p {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 18.83px;
            color: #707073;
         }
      }
   }
}
