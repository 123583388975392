.myTasks {
   header {
      height: 50px;
      background-color: #ccc;

      .myTasksHeader {
         display: flex;
         justify-content: space-between;
         width: 100%;
         margin: 0 20px;
      }
   }

   .section {
      width: 100%;
   }

   .section01 {
      top: 50px;
      height: 345px;
      background-color: #f0f0f0;

      @media (max-width: 767px) {
         height: unset;
      }
   }

   .section2 {
      top: calc(50px + 345px);
      background-color: #dde9ff40;

      .tableText {
         color: var(--blue-purple-purple-1, #4158cf);
         font-size: 22px;
         font-weight: 600;
         line-height: 26px;
      }

      .yourTasks {
         padding: 50px 0 25px 30px;
      }

      .my-tasks-page {
         margin: 0px 30px;
         border: 1px solid #cad6ff;
         border-radius: 16px;
         padding: 40px 30px 30px;
         background-color: #fff;

         @media (max-width: 767px) {
            padding: 40px 0 30px;
            border: none;
         }
      }

      @media (max-width: 767px) {
         background-color: #fff;
         height: auto;
         // padding-bottom: 150px;
      }
   }
}
