.aside-menu-wrapper {
   scroll-behavior: smooth;
   max-height: 95vh !important;
   overflow: auto;

   &::-webkit-scrollbar {
      width: 8px;
   }

   /* Track */
   &::-webkit-scrollbar-track {
      border-radius: 10px;
   }

   /* Handle */
   &::-webkit-scrollbar-thumb {
      background: lightgray;
      border-radius: 10px;
   }

   /* Handle on hover */
   &::-webkit-scrollbar-thumb:hover {
      background: rgb(188, 188, 188);
   }
}

.organization-search-bar {
   padding: 0 12px;
   input {
      height: 41px;
      width: 100%;
      left: 8px;
      top: 68.5px;
      background: var(--kaporg-table-background);
      border-radius: 6px;
      outline: none;
      border: none;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #575757;
      padding: 0 10px;
      &::placeholder {
         color: #aed2b8;
         text-align: center;
      }
   }
}

.no-organization-message {
   color: var(--kaporg-black-text);
   margin: 10px;
}

.menu-minimizer {
   button {
      position: absolute;
      right: -15px;
      bottom: 30%;
      width: 46.6px;
      height: 48.46px;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #252938;
      color: white;
      cursor: pointer;
      border: 1px solid;
      border-color: #a6a6a6;
      border-radius: 5px;
   }
}

.aside-footer {
   margin-top: 15px;
   .back-to-button {
      width: 190px;
      display: flex;
      justify-content: flex-start;
      padding: 10px;
      border-radius: 22px;
      margin: 2.5px auto;
      background: transparent;
      color: #f2f2f2;
      border: none;
      gap: 5px;
      font-size: 11px;
      transition: all 0.5s ease;
      font-size: 12px;

      &:hover {
         background: #707073a1;
      }
   }
}
