.wrapper {
   position: relative;
   padding: 0px 30px;
   margin-top: 20px;
   margin-left: 15px;
   max-width: 550px;

   .absolute {
      top: -15px;
      position: absolute;
   }

   .text {
      color: #626060;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 144%; /* 20.16px */
      letter-spacing: 0.14px;
   }

   .left {
      left: -15px;
   }

   .right {
      right: -15px;
   }

   :global {
      .icon {
         width: 16px;
         height: 16px;
         color: rgba(0, 0, 0, 0.25);
         font-size: 16px;
         line-height: 1;
      }

      .frown {
         left: 0;
      }

      .smile {
         right: 0;
      }
   }
}
