.new-selection-page {
   display: flex;
   flex-direction: column;
   padding: 100px;
   align-items: center;
   margin: 5% 0 3% 0 !important;
   gap: 30px;
   color: #707073;
   border-radius: 10px;
   border: 1px solid #e8e8e8;
   background: #fff;
   box-shadow: 0px 4px 12px 0px #a5b1d9;

   h2 {
      font-weight: 600;
      color: #f58f45;
   }

   .selection-description {
      display: flex;
      justify-content: center;
      width: 100%;

      p {
         font-weight: 500;
         width: 30% !important;
         min-width: 250px;
         text-align: center;
      }

      @media (max-width: 260px) {
         p {
            width: 90%;
            min-width: 100px;
         }
      }
   }

   .selection-import-build-box-group {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;

      .new-selection-box {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         border-radius: 16px;
         background: #d6deff;
         box-shadow: 0px 4px 12px 0px #a5b1d9;
         width: 210px;
         height: 160px;
         border-radius: 20px;

         .selection-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            background: white !important;
            padding: 15px;
            font-size: 24px;
            border-radius: 50%;
            cursor: pointer;

            img {
               width: 25px;
               height: auto;
            }
         }

         h3 {
            margin-top: 13px;
            color: var(--blue-purple-dark-purple, #252938);
            text-align: center;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
         }

         &:hover {
            background: #a9b3dd;
         }
      }

      a {
         color: #5770cb;
      }
   }
}

.selection-import-page {
   margin: 5% auto;
   width: 80%;
   box-shadow: 0px 2px 8px 0px #e0dede;
   border-radius: 28px;
   background-color: white !important;
   padding: 0 0 20px 0;

   .ant-upload-icon {
      display: flex !important;
      align-items: center;
   }

   .ant-upload-list-item-action {
      background-color: rgb(227, 227, 227) !important;
      transition: all 0.3s ease;

      span {
         color: #d11a29c3 !important;
      }
   }

   .download-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
      margin: 0 auto;
      width: 60%;
      height: 28%;
      position: relative;
      background-color: #ece5fd;
      border-radius: 0 0 8px 8px;
      font-weight: 500;
      font-size: 14px;
      text-align: center;

      .download-line {
         width: 100%;
         height: 10px;
         border-radius: 0 0 8px 8px;
         left: 0;
         top: 0;
         background-color: #8153f3;
         position: absolute;
      }

      .ant-btn {
         display: flex;
         align-items: center;
         background-color: #8153f3;
      }
   }

   .upload-selection-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 3%;

      .ant-upload-wrapper {
         display: flex !important;
         flex-direction: column;
         align-items: center;
      }

      .ant-upload-list {
         width: 100% !important;
         display: flex;
         flex-direction: column;
         align-items: center;
      }

      .ant-btn-default {
         border: none;
         transition: all 0.3s ease-in-out;

         &:hover {
            background-color: #48506f;
            color: white;
         }
      }

      h2 {
         font-size: 24px;
         margin-bottom: 2%;
      }

      button {
         display: flex;
         justify-content: center;
         align-items: center;
         height: 50px;
         width: 100%;
         border-radius: 50px;
         color: white;
         background-color: #576596;
      }

      .selection-management {
         display: flex;
         justify-content: center !important;
         width: 100%;
         margin-top: 30px !important;
      }

      .ant-form-item {
         margin-bottom: 10px !important;
      }

      .ant-form-horizontal {
         display: flex;
         flex-direction: column;
         align-items: center;
         width: 80% !important;

         .ant-form-item {
            width: 60% !important;
         }

         .ant-form-item-row {
            display: block !important;
         }

         .ant-form-item-control {
            width: 100% !important;
            min-width: 100%;
         }
      }

      .new-selection-button-group {
         gap: 20px !important;
         justify-content: center;
         margin-top: 30px;
         margin-bottom: 10px;

         button {
            width: 130px;
            border-radius: 8px;
            transition: all 0.3s ease-in-out;
         }

         .cancel-button {
            background-color: transparent;
            color: #252938;
            border: 1px solid #cdced2;

            &:hover {
               border-color: #48506f;
            }
         }

         .submit-button {
            color: white;

            &:hover {
               background-color: #48506f;
            }
         }
      }

      label {
         font-weight: 500;

         &::after {
            font-weight: 500;
         }
      }
   }
}

@media (max-width: 768px) {
   .download-section {
      width: 90% !important;
   }
}

@media (max-width: 568px) {
   .selection-import-page {
      width: 100% !important;

      .upload-selection-section {
         .ant-form-horizontal {
            width: 100% !important;
         }
      }
   }
}

.create-new-selection-page {
   margin: 5% auto;
   width: 80%;
   box-shadow: 0px 2px 8px 0px #e0dede;
   border-radius: 28px;
   background-color: white !important;
   padding: 4%;
   display: flex;
   justify-content: center;
   overflow-x: hidden !important;

   .ant-form-horizontal {
      display: flex;
      flex-direction: column;
      width: 60%;

      .ant-row {
         display: block;

         label {
            font-weight: 600;
         }
      }
   }

   .create-selection-button-group {
      display: flex;
      justify-content: center;
      width: 100%;
      gap: 10px;

      .ant-col {
         margin: 0;
      }

      .previous {
         background-color: transparent;
         color: #252938;
         border: 1px solid #cdced2;

         &:hover {
            border-color: #48506f;
         }
      }

      .next-button {
         background-color: #576596;

         &:hover {
            background-color: #48506f;
            color: white;
         }
      }

      button {
         width: 130px;
         height: 40px;
         transition: all 0.3s ease-in-out;
      }
   }
}

@media (max-width: 568px) {
   .ant-form-horizontal {
      width: 80% !important;
   }
}

@media (max-width: 376px) {
   .create-new-selection-page {
      width: 100%;
   }

   .ant-form-horizontal {
      width: 90% !important;
   }
}

.selection-list {
   padding: 3% 8%;
   margin: 2%;
   background-color: white;
   box-shadow: 0px 2px 8px 0px #e8e8e8;
   border-radius: 8px;

   .new-selection-button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: #576596;
      color: white;
      min-width: 150px;
      height: 45px;
      transition: all 0.3s ease-in-out;
      font-weight: 600;

      &:hover {
         background-color: #394261;
         color: white;
      }
   }

   // selection  table

   .selection-table {
      overflow-x: auto !important;
   }

   .ant-table-tbody tr :nth-child(3) {
      text-align: start !important;
      padding-right: 0 !important;
   }

   table {
      .table-layout {
         table-layout: fixed;
         width: 100% !important;
      }
   }

   th,
   td {
      width: 100px;
      padding: 0;
   }

   @media (max-width: 768px) {
      .table-control-group {
         display: block !important;
      }
   }

   .ant-input-affix-wrapper {
      max-height: 40px;
      width: 50%;
      min-width: 180px;
   }

   @media (max-width: 520px) {
      .ant-input-affix-wrapper {
         width: 80%;
      }
   }

   .ant-select-selector {
      min-width: 120px !important;
   }

   .ant-message {
      background-color: #663399 !important;
   }

   tr {
      th {
         width: 25%;
         background-color: #d6deff !important;
         color: "#576596";
      }
   }

   .ant-table-thead>tr>td {
      background-color: #d6deff !important;
   }

   .ant-select-selection-search {
      width: 140px !important;
   }

   .table-control-group {
      flex-wrap: wrap;

      .table-control {
         width: 100%;
         gap: 15px;

         .ant-select-selector {
            display: flex;
            align-items: center;
            height: 40px !important;
         }

         button {
            border: none;
            height: 40px;
            width: 90px;
            background: #576596;
            color: white;
            border-radius: 50px;
            padding: 5px;
            transition: all 0.3s ease-in-out;

            &:hover {
               background-color: #394261;
            }
         }

         .filter-search-group {
            width: 100%;
            display: flex;
            gap: 15px;

            .ant-select {
               width: 35%;
               min-width: 150px;
               margin-right: 5px;
               margin-bottom: 10px;
            }

            @media (max-width: 420px) {
               .ant-select {
                  width: 80%;
               }
            }
         }
      }

      .selection-list-search-button {
         display: block !important;

         button {
            display: flex !important;
            justify-content: center !important;
            align-items: center;
            border: none;
            height: 40px;
            width: 100px;
            background: #576596;
            color: white;
            border-radius: 50px;
            padding: 5px;
            transition: all 0.3s ease-in-out;

            margin-left: 10px;

            &:hover {
               background-color: #394261;
               color: white;
            }
         }
      }
   }

   .ant-pagination {
      display: flex;
      justify-content: center;
   }

   @media (max-width: 700px) {
      .table-control-group {
         flex-direction: column-reverse;

         .new-member-button {
            margin-bottom: 10px;
            margin-left: 0 !important;
            width: 100% !important;
         }
      }
   }

   @media (max-width: 699px) {
      .table-control {
         flex-direction: column;
      }
   }

   @media (max-width: 519px) {
      .filter-search-group {
         display: block !important;

         .ant-select-selector {
            margin-right: 5px;
            margin-top: 5px;
         }
      }

      .selection-list-search-button {
         margin-top: 15px;
      }
   }
}

.download-btn {
   background-color: #8153f3;
   margin-top: 10px;
   padding: 10px 20px;
   border-radius: 12px;
   font-weight: 400;
   color: white;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";

   &:hover {
      color: white;
      background-color: #9269f7;
   }
}