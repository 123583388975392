.ant-form-item
   .ant-form-item-label
   > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
   display: none;
}

.create-header {
   color: var(--Blue-purple-Purple-2, #9baceb);
   font-family: Poppins;
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px; /* 150% */
   h2:nth-of-type(1) {
      span {
         display: inline-flex;
         align-items: center;
         justify-content: center;
         border: 3px solid #b35816;
         border-radius: 50%;
         height: 50px;
         width: 50px;
      }
   }
}

.register-page {
   display: flex;
   flex-direction: column;
   overflow-x: hidden;

   .heading-select-plan {
      color: var(--Blue-purple-Purple-1, #5770cb);

      /* Basic/Headline Large */
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 144%; /* 37.44px */
   }
   .paragraph-total-year {
      border-radius: 5px;
      border: 1px solid var(--Blue-purple-Purple-2, #9baceb);
      background: var(--Blue-purple-Purple-4, #eef2ff);
      display: flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      align-self: stretch;
      color: var(--Blue-purple-Purple-1, #5770cb);
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
   }

   .ant-form-horizontal {
      width: 95%;
   }

   .register-form {
      .create-section {
         display: flex;
         flex-direction: column;
         padding: 4% 5%;
         align-items: center;
      }

      .register-adminstrator-information {
         display: flex;
         gap: 6%;

         .heading-create-account {
            color: var(--Blue-purple-Purple-1, #5770cb);

            /* Basic/Headline Large */
            font-family: Poppins;
            font-size: 26px;
            font-style: normal;
            font-weight: 600;
            line-height: 144%; /* 37.44px */
         }

         .paragraph-create-account {
            color: var(--Blue-purple-Purple-1, #5770cb);
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 144%; /* 25.92px */
         }

         .ant-row {
            display: block;

            .ant-input-affix-wrapper {
               border-radius: 6px !important;
               position: relative;

               input {
                  width: 100%;
                  padding-right: 30px;
                  min-height: 38px !important;
                  height: 38px !important;
               }

               span {
                  position: absolute;
                  top: 50%;
                  right: 5px;
                  transform: translateY(-50%);
               }
            }

            .ant-input-password {
               width: 400px;
               padding: none !important;
               border-radius: 12px;

               input {
                  border-radius: 6px;
                  min-height: 40px;
               }

               .ant-input-suffix {
                  padding: 1px;
               }
            }

            label {
               font-weight: 600;
               color: black !important;
            }
         }

         .left-block {
            display: flex;
            flex-direction: column;
            width: 100%; /* Adjusted width */

            input {
               border-radius: 6px !important;
               height: 40px;
            }
         }

         .right-block {
            padding-right: 6%;
         }
      }

      .ant-input-affix-wrapper {
         padding: 0 !important;

         input {
            min-height: 40px;
            padding: 4px 11px;
         }
      }

      .ant-select {
         height: 40px !important;
      }

      .ant-form-item-control-input {
         .ant-form-item-control-input-content {
            input {
               height: 40px !important;
            }
         }
      }
      @media (max-width: 720px) {
         .register-adminstrator-information {
            display: flex;
            flex-direction: column;
            gap: 6%;
         }
      }
   }

   .ant-select-selector {
      width: 400px !important;
   }

   #basic_country {
      border: none !important;
   }

   label {
      font-weight: 600;
      color: black !important;
   }

   @media (min-width: 1480px) {
      .create-section {
         align-items: center !important;
      }
   }

   @media (max-width: 1480px) {
      .ant-select-selector {
         width: 380px !important;
      }
   }

   @media (max-width: 1390px) {
      .ant-select-selector {
         width: 350px !important;
      }
   }

   @media (max-width: 1200px) {
      .ant-select-selector {
         width: 300px !important;
      }
   }

   @media (max-width: 1100px) {
      .ant-select-selector {
         width: 250px !important;
      }
   }

   @media (max-width: 992px) {
      .ant-select-selector {
         width: 400px !important;
      }
   }

   @media (max-width: 920px) {
      .ant-select-selector {
         width: 350px !important;
      }
   }

   @media (max-width: 820px) {
      .ant-select-selector {
         width: 300px !important;
      }
   }

   @media (max-width: 720px) {
      .ant-select-selector {
         width: 300px !important;
      }

      .ant-form-horizontal {
         width: 80% !important;
      }
   }

   input {
      width: 400px !important;
      border: 2px solid #e7e7e7 !important;
      border: none;
      border-radius: 8px;
      outline: 0;
      outline: none;
      -webkit-appearance: none;
   }

   @media (max-width: 1480px) {
      input {
         width: 380px !important;
      }
   }

   @media (max-width: 1390px) {
      input {
         width: 350px !important;
      }
   }

   @media (max-width: 1200px) {
      input {
         width: 300px !important;
      }
   }

   @media (max-width: 1100px) {
      input {
         width: 250px !important;
      }
   }

   @media (max-width: 992px) {
      input {
         width: 400px !important;
      }
   }

   @media (max-width: 920px) {
      input {
         width: 350px !important;
      }
   }

   @media (max-width: 820px) {
      input {
         width: 300px !important;
      }
   }

   @media (max-width: 720px) {
      input {
         width: 100% !important;
      }

      .ant-input-password {
         input {
            width: 100% !important;
            border-radius: 8px;
         }
      }

      .company-form-section {
         display: flex;
         flex-direction: column !important;
      }
   }

   input:focus {
      background-color: #f2f2f2;
      outline: 0;
      outline: none;
   }

   .register-button-group {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      // justify-content: center !important;
      // gap: 25px;

      button:nth-of-type(1) {
         border: 2px solid #e7e7e7;

         background-color: transparent;
      }

      button {
         // width: 120px;
         width: 200px;
         height: 40px;
         cursor: pointer;
         border-radius: 8px !important;
      }
   }

   .ant-row {
      display: block;
   }

   h3 {
      color: #7c7c80;
      font-size: 16px;
      font-weight: 600;
   }

   .create-section {
      .ant-form-item-explain-error {
         max-width: 400px !important;
      }
   }
}

@media (min-width: 1550px) {
   .register-page {
      display: flex;
      justify-content: center;
   }

   .register-button-group {
      button {
         width: 100%;
      }
   }
}

@media screen and (max-width: 560px) {
   .register-button-group {
      display: flex;
      flex-direction: column;
      align-items: center;
   }
}

.lastpage-title {
   display: flex;
   flex-direction: column;
   gap: 40px;

   h2 {
      color: var(--orange-orange, #f58f45);
      font-family: Nunito Sans;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      /* 62.5% */
   }
}

.lastpage-content-text {
   h2 {
      color: #474747;
      text-align: center;
      font-family: Nunito Sans;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 25px;
      /* 104.167% */
   }
}

.lastpage-img {
   img {
      width: 500px;
      height: auto;
   }

   @media (min-width: 1200px) {
      .lastpage-img {
         img {
            width: 611px !important;
         }
      }
   }
}

.lastpage-button {
   button {
      display: inline-flex;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: #eb7115;
      color: #fff;
      text-align: center;
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      /* 128.571% */
   }
}

.lastpage-line {
   border: 8px solid #eb7115;
   position: absolute;
   width: 100%;
   bottom: 0;
}

.register-payment-section {
   display: flex;
   justify-content: center;
   gap: 5%;

   button {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 300px;
      // height: 370px;
      height: 440px;
      // background-color: #f5f5f5;
      // border: 2px solid #bebebe;
      border: 2px solid #9baceb;
      border-radius: 12px !important;
      padding: 0;
      padding-bottom: 20px;
      transition: all 0.3s ease;

      .plan-title {
         width: 100%;
         // background-color: #7c7c80;
         // padding: 10px;
         padding: 26px 20px;
         font-size: 24px;
         font-weight: 500;
         // color: white;
         color: #5770cb;
         border-radius: 9px 9px 0 0 !important;
         gap: 10px;
         display: flex;
         flex-direction: column;
         align-items: center;

         .months {
            color: white;
            padding: 6px 8px 6px 8px;
            background-color: #f58f45;
            border-radius: 20px;
            width: 125px;
            font-size: 14px;
            font-weight: 600;
            font-family: "Poppins";
            text-align: center;
         }
      }

      svg {
         color: #de8344;
      }

      &:hover {
         border-color: #9baceb !important;
         color: #de8344 !important;
      }

      .content {
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         text-align: start;
         padding: 30px;

         p {
            font-family: "Poppins";
            font-size: 14px;
            display: flex;
            align-items: center;
            gap: 3px;
         }
      }

      &:focus,
      &.selected {
         border-color: #9baceb;
         box-shadow: 0px 6px 6px -3px rgba($color: #a5b1d9cc, $alpha: 0.1);
         transform: scale(1.03);
      }

      &:focus,
      &.selected {
         .plan-title {
            background-color: #eef2ff;
            border: 1px solid #eef2ff;
         }

         svg {
            color: #de8344;
         }
      }

      .payment {
         font-size: 34px;
         font-weight: 600;
         margin-bottom: 2rem;

         span {
            font-size: 14px;
            color: #999999;
         }

         .annuallyPrice {
            font-size: 40px;
            color: #f58f45;
            font-weight: 700;
         }
      }
   }

   a {
      color: #7c7c80 !important;
      text-decoration: underline !important;
      font-weight: 500;
      margin-bottom: 10px;
   }
}

.total-per-year {
   background-color: #eef2ff;
   border: 1px solid #d9e3ff;
   padding: 10px;
   width: 640px;
   height: 44px;
   text-align: center;
   margin-top: 2rem;
   border-radius: 10px;
   color: #5770cb;
   font-size: 14px;
   font-family: "Poppins";
}

@media screen and (max-width: 610px) {
   .register-payment-section {
      flex-direction: column !important;
      align-items: center;

      button {
         margin-bottom: 20px;
         height: 100%;
      }
   }

   .total-per-year {
      width: 100%;
   }
}

.ant-modal-content {
   padding: 8% !important;
   color: #7c7c80;

   .ant-modal-close {
      .anticon-close {
         display: flex;
         align-items: center;
         justify-content: center;
         border: 2px solid #7c7c80 !important;
         border-radius: 4px;
         width: 24px !important;
         height: 24px !important;
      }
   }

   h2 {
      text-align: start !important;
      color: #de8344;
      font-size: 26px;
      font-weight: 700;
      padding-right: 8% !important;
      padding-left: 8% !important;
   }

   p {
      padding-right: 8% !important;
      padding-left: 8% !important;
   }

   h4 {
      color: #252938;
      font-size: 19px;
      font-weight: 600;
      padding-right: 8% !important;
      padding-left: 8% !important;

      span {
         color: #7c7c80;
         font-size: 14px;
      }
   }

   ul {
      width: 80%;
      padding-right: 8% !important;
      padding-left: 10% !important;

      letter-spacing: 1px;
      margin: 0;
      padding: 12px;
      width: 100% !important;
      display: flex !important;
      justify-content: center !important;
      flex-direction: column;

      li {
         margin-bottom: 20px;
      }
   }

   .ant-modal-footer {
      display: none;
   }
}

.review-pay-section {
   padding: 0;
   width: 100%;

   .create-information-review {
      display: flex;
      justify-content: space-between;
      padding: 4% 8%;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 8px;
      gap: 10%;

      .left-block {
         width: 50%;
      }

      .right-block {
         width: 50%;
      }

      div {
         margin-bottom: 20px;
         width: 90%;
      }
   }

   @media (max-width: 1300px) {
      .create-information-review {
         flex-direction: column;

         .right-block {
            width: 100%;
         }

         .left-block {
            width: 100%;
         }
      }

      h2 {
         width: 100%;
      }
   }

   @media (max-width: 992px) {
      .create-information-review {
         flex-direction: row;
      }
   }

   @media (max-width: 822px) {
      .create-information-review {
         flex-direction: column;

         .right-block {
            width: 100%;
         }

         .left-block {
            width: 100%;
         }
      }
   }

   h2 {
      font-size: 18px;
      color: #de8344;
      font-weight: 600;
      margin-bottom: 20px;
   }

   p {
      font-size: 14px;
      margin-bottom: 0;
      color: #bebebe;
   }

   span {
      display: inline-block;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      word-wrap: break-word !important;
   }

   .plan-information-review {
      display: flex;
      justify-content: space-between;
      padding: 4% 8%;
      margin-top: 5%;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 8px;
      gap: 10%;

      .left-block {
         width: 50%;
      }

      .right-block {
         width: 50%;

         ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
               margin-bottom: 15px;

               span {
                  display: flex;
                  align-items: center;
                  gap: 5px;

                  svg {
                     color: #de8344;
                  }
               }
            }
         }
      }

      div {
         margin-bottom: 20px;
         width: 90%;
      }
   }

   @media (max-width: 768px) {
      .plan-information-review {
         flex-direction: column;

         ul {
            width: 100%;
         }

         .right-block {
            width: 100%;
         }

         .left-block {
            width: 100%;
         }
      }
   }

   h2 {
      font-size: 18px;
      color: #de8344;
      font-weight: 600;
      margin-bottom: 20px;
   }

   p {
      font-size: 14px;
      margin-bottom: 0;
      color: #bebebe;
   }

   span {
      display: inline-block;
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      word-wrap: break-word !important;
   }
}

.payment-method {
   .ant-radio-group {
      display: flex;
      gap: 20px;

      label {
         display: flex;
         justify-content: center !important;
         align-items: center;
         width: 200px;
         height: 80px;
         padding: 20px;
         box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
         border-radius: 8px;

         p {
            display: inline-flex;
            align-items: center;
            width: 50px;
         }
      }

      .ant-radio {
         width: 0;
         margin-right: 20px;

         &.ant-radio-checked {
            .ant-radio-inner {
               border-color: #252938 !important;
               background-color: #252938 !important;
            }
         }
      }
   }

   span:nth-child(2) {
      display: flex;
      align-items: center;
   }

   .ant-form-item-label {
      margin-top: 20px;
      padding-top: 10px;
      padding-bottom: 10px;

      label {
         color: #de8344;
         font-size: 18px;
         font-weight: 600;
      }
   }
}

@media (max-width: 1100px) {
   .payment-method {
      .ant-radio-group {
         flex-direction: column;
         align-items: center;
      }

      .ant-row {
         display: block;
         text-align: center;
      }
   }
}

@media (max-width: 992px) {
   .payment-method {
      .ant-radio-group {
         flex-direction: row;
      }

      .ant-row {
         display: block;
         text-align: start;
      }
   }
}

@media (max-width: 768px) {
   .payment-method {
      .ant-radio-group {
         flex-direction: column;
         align-items: center;
      }

      .ant-row {
         display: block;
         text-align: center;
      }
   }
}

@media (max-width: 720px) {
   .register-page .register-form .register-adminstrator-information .right-block {
      padding-right: 0;
   }

   .ant-input-password {
      width: 100% !important;
   }

   input {
      width: 100% !important;
      border-radius: 8px;
   }

   .company-form-section .ant-select-selector {
      width: 100% !important;
   }
}

.company-form-section {
   display: flex;
}

@media (max-width: 992px) {
   .kap-survey-login {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      width: 100% !important;
   }
}

.kap-survey-login {
   //  margin-top: 0 !important;
   //  margin-left: 10%;
   margin-top: 0 !important;
   margin-left: 0 !important;
   display: flex;
   justify-content: center;
   align-items: center;
   // width: 70%;
   width: 100%;

   .login-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h1 {
         color: var(--orange-orange, #f58f45);
         font-family: Poppins;
         font-size: 40px;
         font-style: normal;
         font-weight: 600;
      }

      h4 {
         color: var(--greys-dark-gray, #707073);
         font-family: Poppins;
         font-size: 22px;
         font-style: normal;
         font-weight: 400;
         line-height: 24px;
         /* 109.091% */
         margin: 20px 0 16px 0;
      }
   }

   form {
      max-width: 416px !important;
      width: 100%;

      input & .ant-form-item-control-input-content {
         height: 100px;
      }

      button {
         min-height: 35px;
      }
   }

   .ant-row {
      display: block;

      label {
         font-weight: 600;

         &::after {
            display: none;
         }
      }
   }

   #basic_remember {
      label {
         color: #707073;
      }

      a {
         color: #707073;
         font-weight: 600;
         text-decoration: underline;
      }
   }

   .ant-input {
      width: 100% !important;
   }

   .errorMessage {
      color: red;
   }
}

@media screen and (max-width: 540px) {
   .kap-survey-login {
      margin-top: 20% !important;
      width: 100% !important;
      margin-left: 0 !important;
   }
}

.kapSurveyPrimaryButton {
   display: inline-flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   flex-shrink: 0;
   border-radius: 8px;
   background: var(--Blue-purple-Purple-1, #5770cb) !important;
   box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1) !important;
   color: white !important;
   font-weight: 500;
}

.kapSurveyPrimaryButton:hover:not(:disabled) {
   background: var(--Blue-purple-Purple-2, #9baceb !important);
   box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);
   color: var(--Greys-White, #fff);
   border: none !important;
}

.kapSurveyPrimaryButton:disabled {
   display: inline-flex;
   height: 44px;
   padding: 4px 16px;
   justify-content: center;
   align-items: center;
   gap: 8px;
   flex-shrink: 0;
   border-radius: 8px;
   background: var(--Greys-Light-Gray-1, #f2f2f2);
   color: var(--Greys-Medium-Gray-3, #bfbfbf);
   cursor: not-allowed;
}

.kap-survey-login-aside {
   width: 100%;
   max-width: 300px;
   height: 100%;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: 100% 66%;
   min-width: 50%;
}

.kap-survey-login-aside-mobile {
   display: none;
   width: 100%;
   height: 100%;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: 100% 66%;
   min-width: 50%;
}

.login-page-aside-logo {
   position: absolute;
   right: 1%;
   padding: 15px 20px;
}

.login-layout {
   .layout-content-2 {
      position: relative;
      overflow: auto;

      .login-page-aside-logo {
         position: absolute;
         top: 20px;
         right: 20px;
      }
   }
}

.forgotPasswordLayout {
   display: flex;
   flex-direction: column;
   height: 100%;
   border-top: 12px solid #5770cb;
   position: relative;

   .logo {
      text-align: right;
      padding-top: 25px;
      margin-right: 25px;

      @media (max-width: 767px) {
         width: 100%;
      }
   }

   .forgotPasswordPage {
      display: flex;
      height: inherit;
      margin-top: -50px;

      .child {
         flex-basis: 100%;
         display: flex;
         justify-content: center;
      }

      .image {
         align-items: center;

         img {
            width: 70%;
            height: 90%;
            object-fit: contain;
         }

         @media (max-width: 600px) {
            display: none;
         }
      }

      .infos {
         flex-direction: column;
         align-items: flex-start;

         .title {
            color: #f58f45;
            font-weight: 600;
            font-size: 35px;

            @media (max-width: 600px) {
               text-align: center;
               font-size: 24px;
            }
         }

         .description {
            @media (max-width: 600px) {
               text-align: center;
            }

            @media (min-width: 1200px) {
               width: 75%;
            }
         }

         .innerchild {
            width: 100%;
            padding-right: 70px;

            .ant-form-horizontal {
               width: 100% !important;
            }

            .error {
               color: #f58f45;
            }

            .forgotPasswordForm {
               .email {
                  color: var(--Blue-purple-Dark-purple, #252938);
                  font-family: Poppins;
                  font-weight: 600;
                  margin-bottom: 4px;
               }

               .emailInput {
                  margin-bottom: 30px;

                  input {
                     border-radius: 5px;
                     border: 1.5px solid var(--Greys-Mid-Gray, #999);
                     background: var(--Greys-Light-Gray-1, #f2f2f2);
                     padding: 7px 16px;
                     font-family: Poppins;
                     font-size: 12px;
                     font-weight: 500;
                     line-height: 24px;
                     letter-spacing: -0.12px;
                  }
               }
            }

            .mobileButton {
               display: flex;
               gap: 8px;

               @media (max-width: 600px) {
                  justify-content: center;
               }
            }

            @media (max-width: 767px) {
               padding-right: 0;
               width: 90%;
               margin: 0 auto;
            }

            @media (min-width: 1200px) {
               width: 550px;
               padding-right: 0;
            }
         }

         @media (max-width: 767px) {
            max-width: 330px;
         }
      }

      @media (min-width: 1200px) {
         gap: 0px;
      }
   }

   .waveContainer {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: -1;
   }

   @media (max-width: 767px) {
      flex-direction: column;
      gap: 0;
      align-items: center;
   }
}

@media (max-width: 992px) {
   .layout-content-2 {
      .login-page-aside-logo {
         // position: relative !important;
         // display: flex;
         // justify-content: center;
         // align-items: center;
         // margin-top: -50px;

         position: absolute;
      }
   }

   .kap-survey-login-aside {
      max-width: 100%;
      max-height: 300px;
      background-position: 100% 43%;
      display: none;
   }

   .kap-survey-login-aside-mobile {
      display: block;
      max-height: 250px;
      background-position: 50% 50%;
   }
}

@media (max-width: 992px) {
   .registration-stepper {
      .ant-steps.ant-steps-vertical {
         flex-direction: row;
      }

      .ant-steps-item-tail::after {
         display: none;
      }
   }
}

@media (max-width: 568px) {
   .ant-steps-item-content {
      .ant-steps-item-title {
         display: none !important;
      }
   }
}

.conf-modal-wrapper {
   .ant-modal {
      max-width: 510px !important;
   }

   .ant-form {
      width: 100% !important;
      min-width: 100%;
   }

   .conf-inner {
      text-align: center;

      h1 {
         font-size: 26px;
         font-weight: 700;
         color: #4158cf;
      }

      p {
         font-size: 16px;
         line-height: 22px;
         color: #555555;
         margin-bottom: 50px;
         padding: 0 !important;
      }
   }

   .code-input {
      text-align: center;
   }
}

.login-form-content {
   .kap-survey-login {
      form {
         .ant-input-affix-wrapper {
            padding: 0 !important;
            position: relative;

            input {
               padding: 4px 30px 4px 11px !important;
               border-radius: 6px !important;
            }

            .ant-input-suffix {
               position: absolute;
               right: 10px;
               top: 50%;
               transform: translateY(-50%);
            }
         }
      }
   }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none !important;
   margin: 0 !important;
}

//   .ant-modal-content {
//     padding: 80px 25px 50px !important;
//   }
