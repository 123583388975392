.stripe-success-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  place-content: center;
  place-items: center;
}

.stripe-success-title {
  color: var(--orange-orange, #F58F45);
  font-family: Nunito Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}

.stripe-success-description {
  color: #474747;
  text-align: center;
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}

.stripe-success-button {
  color: #FFF;
  text-align: center;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;

  border: none;
  padding: 5px 10px;
  border-radius: 8px;
  background: #EB7115;
}

.stripe-success-button:hover {
  cursor: pointer;
  background: #FFF;
  border: 1px solid #EB7115;
  color: #EB7115;
}

@media screen and (max-width: 640px) {
  .stripe-success-title {
    font-size: 30px;
  }

  .stripe-success-description {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .stripe-success-title {
    font-size: 24px;
  }

  .stripe-success-description {
    font-size: 16px;
  }
}

@media screen and (max-width: 380px) {
  .stripe-success-title {
    font-size: 20px;
  }

  .stripe-success-description {
    font-size: 13px;
  }
}

@media screen and (max-width: 310px) {
  .stripe-success-title {
    font-size: 16px;
  }

  .stripe-success-description {
    font-size: 12px;
  }
}