.preview-language {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid #9baceb;
   background-color: #d9e3ff;
   padding: 16px;
   gap: 24px;

   &--select {
      width: 100%;

      & label {
         width: 100%;
         display: block;
         margin-bottom: 8px;

         font-family: Poppins;
         font-size: 16px;
         font-weight: 500;
         line-height: 22px;
         color: #3a3737;
      }
   }

   &--actions {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;

      &_print {
         width: 100%;
         & button {
            width: 100%;
            border-radius: 8px;
            border: 1px solid #5770cb;
            color: #5770cb;

            & span {
               font-family: Poppins;
               font-size: 14px;
               font-weight: 500;
               line-height: 22px;
            }
         }
         .ant-btn {
            width: 100%;
            height: auto;
            padding: 10px 16px;
         }
      }
      &_export {
         width: 100%;
         & button {
            width: 100%;
            border-radius: 8px;
            color: #fff;

            border: 1px solid #9baceb;
            background: #5770cb;
            box-shadow: 0px 4px 16px 0px rgba(19, 30, 69, 0.1);

            & span {
               font-family: Poppins;
               font-size: 14px;
               font-weight: 500;
               line-height: 22px;
            }

            &:hover {
               color: #fff !important;
               background: #003daa;
               border: 1px solid #003daa !important;
            }
         }
         .ant-btn {
            width: 100%;
            height: auto;
            padding: 10px 16px;
         }
      }
   }
}

@media (min-width: 640px) {
   .preview-language {
      height: 102px;
      flex-direction: row;
      padding: 20px 40px;

      &--select {
         width: 336px;
      }

      &--actions {
         flex-direction: row;
         width: auto;
         &_print,
         &_export {
            width: auto;
            & button {
               width: auto;
            }
            .ant-btn {
               width: auto;
            }
         }
      }
   }

   .actions-print--submission {
      justify-content: flex-end;
   }
}
