.draft-list {
   max-height: 400px;
   overflow-y: auto;

   &-item-item {
      margin-bottom: 16px;

      &_selected {
         background-color: #eef2ff;
         border: 1px solid #d9e3ff;
      }
   }

   .single-draft-card {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #d9e3ff;

      &--icon {
         display: none;
         width: 91px;
         height: 100%;
         background-color: #eef2ff;
         border-radius: 8px;
      }

      &--info-container {
         flex-direction: column;
      }

      &--info {
         padding: 13px 14px 4px 14px;

         & h6,
         p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
         }

         & h6 {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            color: "#222222";
         }

         & p {
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
            color: #777777;
            line-clamp: 2 !important;
            -webkit-line-clamp: 2 !important;
         }
      }

      &--actions {
         justify-content: flex-end;
         padding: 8px 9px;
         background-color: #d9e3ff;
      }
   }

   &-action-button {
      min-width: 44px;
      width: auto;
      height: 44px;
      background-color: #eef2ff;
      border-radius: 8px;
      border: 1px solid #d9e3ff;
   }

   @media (min-width: 640px) {
      &-item-item {
         margin-bottom: 24px;
      }

      .single-draft-card {
         height: 91px;

         &--info-container {
            flex-direction: row;
         }

         &--info {
            padding: 6px 16px;

            & h6,
            p {
               line-clamp: 1 !important;
               -webkit-line-clamp: 1 !important;
            }
         }

         &--actions {
            margin-right: 16px;
            padding: 0;
            background: none;
         }
      }
   }

   @media (min-width: 768px) {
      &-item-item {
         margin-bottom: 32px;
      }

      .single-draft-card {
         &--icon {
            display: flex;
         }

         &--info {
            padding: 9px 24px;
         }
      }
   }
}
