.accountSummary {
   padding: 20px;

   .usageStatistics {
      padding: 10px 20px;
      margin-top: 40px;
      border-radius: 8px;
      background: rgba(221, 233, 255, 0.25);
      border: 1.5px solid #cad6ff;
      font-family: "Poppins";

      h4 {
         text-align: center;
         font-size: 16px;
         font-weight: 500;
      }

      h5 {
         margin-top: 60px;
         color: #5770cb;
         font-size: 56px;
         font-weight: 600;
      }

      h6 {
         color: #5770cb;
         font-size: 18px;
         padding-bottom: 15px;
         font-weight: 600;
      }

      .label-text {
         margin-top: 0;
      }

      .verticalLayout {
         display: flex;
         flex-direction: column;
      }
      .box {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: space-between;
         border-radius: 10px;
         border: 1.5px solid #d9e3ff;
         background: #fff;
         padding: 24px 40px;
         height: 280px;

         .apexcharts-legend {
            justify-content: space-between;
         }

         .apexcharts-legend-text {
            color: #999 !important  ;
            font-size: 14px !important;
         }

         .apexcharts-datalabels-group {
            text:nth-child(1) {
               display: none;
            }

            text:nth-child(2) {
               font-family: "Poppins" !important;
               fill: #5770cb;
               font-weight: 600;
            }
         }

         p {
            color: #999;
         }
      }
      .minibox {
         flex-direction: row;
         height: 100px;
         padding: 24px;

         .dataDescription {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            font-family: "Poppins";

            h4 {
               font-size: 36px;
               font-weight: 600;
            }

            span:nth-child(1) {
               font-weight: 600;
            }

            span:nth-child(2) {
               color: #999;
            }
         }
      }
   }

   @media only screen and (min-width: 576px) {
      padding: 70px;
   }
}
