.preview-actions {
   position: sticky;
   bottom: 0;
   left: 0;
   width: 100%;

   padding: 20px 0;
   background: linear-gradient(180deg, #fefefe -16.89%, #e9e9e9 131.62%);
   box-shadow: 0px -10px 20px 0px #5770cb26;

   &--button {
      height: auto;
      border-radius: 8px;
      border: 1px solid #4158cf;

      &_save,
      &_save-survey,
      &_see,
      &_submit {
         padding: 10px 16px;
         font-family: Poppins;
         font-size: 14px;
         font-weight: 500;
         line-height: 22px;
         color: #4158cf;
      }

      //   &_save-survey {
      //   }

      &_save {
         display: flex;
         justify-content: center;
         align-items: center;
         background: #eef2ff;

         &:hover {
            background: #4158cf14;
            border-color: #4158cf !important;
            color: #4158cf !important;
         }

         .title-lg {
            display: none;
         }
      }

      &_see {
         display: none;
         &:hover {
            background: #4158cf14;
            border-color: #4158cf !important;
            color: #4158cf !important;
         }
      }

      &_submit {
         display: none;
         color: #ffffff;
         background: #5770cb;
         box-shadow: 0px 4px 16px 0px #131e451a;
         border: none;

         &:hover {
            background: #003daa;

            color: #ffffff !important;
         }
      }
   }

   @media (min-width: 640px) {
      &--button {
         &_save-survey {
            display: none;
         }

         &_save {
            .title-lg {
               display: inline;
            }
            .title-sm {
               display: none;
            }
         }

         &_see {
            display: inline-block;
         }
         &_submit {
            display: inline-block;
         }
         &_hide {
            display: none;
         }
      }
   }
}
.submit-modal {
   &--description {
      font-size: 18px;
      font-weight: 400;
      line-height: 25.92px;
      color: #707073;
      margin-bottom: 32px;
   }

   &--button {
      width: 186px;
      height: auto;
      border-radius: 8px;
      border: 1px solid #4158cf;
      padding: 10px 16px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: #4158cf;

      &_see {
         &:hover {
            background: #4158cf14;
            border-color: #4158cf !important;
            color: #4158cf !important;
         }
      }

      &_submit {
         color: #ffffff;
         background: #5770cb;
         box-shadow: 0px 4px 16px 0px #131e451a;
         border: none;

         &:hover {
            background: #003daa;

            color: #ffffff !important;
         }
      }

      &_hide {
         display: none;
      }
   }
}
