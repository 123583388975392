.search-filter {
   background: #f9faff;
   padding: 20px 16px;
   margin-bottom: 40px;
   border-radius: 8px;
   border: 1.5px solid #cad6ff;
   width: 100%;

   .search-filter--button_show {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;
      cursor: pointer;

      @media (width <= 768px) {
         justify-content: center;
      }
   }

   .search-filter--text {
      color: #4158cf;
      cursor: pointer;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
   }
}
