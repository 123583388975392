.select_multiple-submission-type {
   &--options {
      &---checkbox {
         &-group {
            display: flex;
            flex-direction: column;
            gap: 8px;
         }
         &-wrapper {
            padding: 8px 14px;
            border: 1px dashed transparent;
            border-radius: 6px;
            display: block;
            margin-inline: 0;
            display: flex;
            align-items: center;
            gap: 4px;

            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0.02em;
            color: #7c7c80;

            &:hover {
               border: 1px dashed #d9d9d9;
            }

            &-checked {
               border: 1px solid #9baceb;
               &:hover {
                  border: 1px solid #9baceb;
               }
            }
         }
         &-inner {
            width: 20px !important;
            height: 20px !important;
            border-radius: 0 !important;

            border-width: 2px !important;
            border-color: #999999 !important;
         }

         &-checked {
            span {
               background-color: #5770cb !important;
               border-color: #5770cb !important;
            }
         }
      }
   }

   &--options {
      &_error {
         border: 1px solid #ff4d4f;
         border-radius: 6px;
      }
      &---max_choice {
         margin-left: 14px;
         margin-top: 10px;
         font-size: 14px;
         color: #666;
      }
   }

   &--error {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      color: #ff4d4f;
   }

   .submissionStyle {
      pointer-events: initial;
      cursor: default;
   }
}
