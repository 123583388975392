.organizations {
   background-color: #eef2ff;
   overflow-y: auto;

   .subheader {
      background-color: #fff;
      margin: 0 !important;
      padding: 0 12px;
   }

   .organizationsBanner {
      position: relative;
      width: 100%;
      height: 190px;
      //   padding: 70px 20px 70px 30px;
      max-height: 378px;
      display: flex;
      justify-content: space-between;
      background-color: #d9e3ff;

      .organizationsBannerInfo {
         flex-grow: 7;
         text-align: center;
         height: 100%;
         display: flex;
         align-items: center;

         h2 {
            color: #5770cb;
            font-family: Poppins;
            font-size: 32px;
            font-weight: 600;
            margin-left: 40px;
         }
      }
      .organizationsBannerImage {
         img {
            width: 100%;
            height: 100%;
         }
      }
   }

   .organizationsBox {
      background-color: #fff;
      width: 90%;
      margin: 30px auto;
      border-radius: 8px;
      padding: 40px 0;

      h2 {
         color: #5770cb;
         font-family: Poppins;
         font-size: 22px;
         font-weight: 600;
         margin-left: 40px;
      }

      h4 {
         color: #fff;
         margin-bottom: 16px;
      }

      p {
         margin: 30px 40px;
         font-size: 14px;
         font-style: normal;
         font-weight: 400;
      }

      .allOrganizations {
         display: flex;
         flex-wrap: wrap;
         /* justify-content: space-evenly; */
         justify-content: center;
         margin: 20px auto;
         // width: 96%;

         .organization {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0px 20px 40px;
            height: 230px;
            width: 390px;
            margin: 10px;
            margin: 12px;
            border-radius: 16px;
            background-color: #5770cb;

            .workspaceBackground {
               position: absolute;
               top: 0;
               left: 0;
               height: 100%;
               width: 100%;
               pointer-events: none;
            }

            .seeWorkspaces {
               display: flex;
               padding: 7px 8px;
               justify-content: space-around;
               border-radius: 6px;
               border: 1px solid var(--Greys-White, #fff);
               width: 165px;

               color: var(--Greys-White, #fff);
               text-align: center;
               font-family: Poppins;
               font-size: 14px;
               font-style: normal;
               font-weight: 500;
               line-height: 22px; /* 157.143% */
               cursor: pointer;
            }
         }

         .deactivatedUser {
            background-color: #9baceb;
         }

         .deactivatedOrganization {
            background-color: #999999;
            cursor: not-allowed;
         }
      }
   }
}
