.introSection {
   background-color: #5770cb;
   height: 345px;
   display: flex;
   justify-content: center;

   .headingText {
      width: 100%;
      align-self: center;
      margin: 0 30px;
      color: #fff;

      span {
         font-size: 40px;
         font-weight: 500;

         @media (max-width: 767px) {
            font-size: 24px;
         }
      }

      p {
         font-size: 14px;
      }
   }

   .apexcharts-legend-series {
      cursor: pointer;
      line-height: normal;
      display: flex;
      align-items: center;
   }

   .apexcharts-legend-text {
      font-family: Poppins !important;
      font-size: 14px !important;
   }

   @media (max-width: 767px) {
      height: unset;
      padding: 40px 16px;
      flex-direction: column;
   }
}

.introSectionDataCollector {
   .chart {
      width: 750px;
      text-align: right;
      align-self: center;
      background: #fff;
      margin: 0 20px;
      border-radius: 10px;
      padding: 0 30px;

      @media (max-width: 767px) {
         width: 100%;
         margin-top: 35px;
         padding: 24px 0;
      }
   }
}
