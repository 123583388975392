.admin-deleted-items {
   height: 100%;
   background: white;

   &--header {
      height: 203px;
      width: 100%;
      overflow: hidden;

      & h3 {
         width: 70%;
         padding: 40px;
         color: #5770cb;
         background: rgba(217, 227, 255, 1);
         -webkit-box-shadow: 0px 0px 57px 100px rgba(217, 227, 255, 1);
         -moz-box-shadow: 0px 0px 57px 100px rgba(217, 227, 255, 1);
         box-shadow: 0px 0px 57px 100px rgba(217, 227, 255, 1);

         @media (min-width: 680px) {
            width: 30%;
         }
      }
   }

   &--container {
      & .admin-deleted-items-tabs {
         &-nav {
            max-width: 100%;
            padding: 40px 0 0 8px;
            background-color: #eef2ff;
            border-bottom: 1.5px solid #d9e3ff;
            @media (min-width: 680px) {
               padding: 40px 0 0 40px;
               &-wrap {
                  overflow: visible !important;
               }
            }
         }

         &-tab {
            transform: translateY(1px);
            border-top: 1.5px solid transparent;
            border-left: 1.5px solid transparent;
            border-right: 1.5px solid transparent;
            padding: 8px 10px;
            border-radius: 6px 6px 0 0;
            margin-left: 4px !important;

            @media (min-width: 680px) {
               padding: 12px 16px;
               margin-left: 6px !important;
            }

            &-btn {
               font-family: Poppins;
               font-size: 15px;
               font-weight: 400;
               line-height: 20px;
               color: #707073;
            }

            &-active {
               border-color: #d9e3ff;
               background: white;

               &-btn {
                  font-weight: 500;
                  color: #5770cb;
               }
            }
         }

         &-ink-bar {
            display: none;
         }
      }
   }
}
