.deleted-items-modal {
   &-close {
      border: 1px solid red;
      &-x {
         .anticon-close {
            border: none !important;
            outline: none !important;
         }
      }
   }

   &-body {
      margin: 68px 6px 32px 6px;

      .deleted-items-modal--title {
         font-family: Poppins;
         & h3 {
            font-size: 22px;
            font-weight: 600;
            line-height: 31.68px;
            text-align: center;
            color: #5770cb;
         }
         & div {
            line-height: 24px;
            letter-spacing: 0.02em;
            text-align: center;
            color: #3a3737;
            max-width: 90%;
            margin: 0 auto;
         }
      }
      .deleted-items-modal--content {
         margin-top: 32px;

         &_table {
            &-thead {
               .deleted-items-modal--content_table-cell {
                  background-color: #eef2ff;
                  color: #5770cb;
               }
            }
            &-tbody {
               .deleted-items-modal--content_table-cell {
                  color: #404040;
               }
            }
         }
      }
   }

   &-footer {
      margin-bottom: 16px;
      display: flex;
      justify-content: center;

      & .ok-button,
      & .cancel-button {
         display: flex;
         justify-content: center;
         align-items: center;
         height: 44px;
         padding: 4px 16px;
         border-radius: 8px;
         border-color: #5770cb;
         font-family: Poppins;
         font-size: 14px;
         font-weight: 500;
         line-height: 22px;
      }

      & .ok-button {
         background: #5770cb;
         color: white;
         box-shadow: 0px 4px 16px 0px #131e451a;

         &:hover {
            background: #485a9c;
         }

         &:disabled {
            background: #5770cb50;
            border-color: #5770cb50;
         }
      }

      & .cancel-button {
         background: white;
         color: #5770cb;
      }
   }
}

@media (min-width: 640px) {
   .deleted-items-modal {
      &-body {
         margin: 68px 16px 32px 16px;
      }

      &-footer {
         margin-bottom: 36px;
      }
   }
}
