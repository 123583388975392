.deleted-tab-container {
   padding: 10px;

   @media (min-width: 450px) {
      padding: 16px;
   }
   @media (min-width: 680px) {
      padding: 24px;
   }
   @media (min-width: 768px) {
      padding: 32px;
   }
   @media (min-width: 1024) {
      padding: 40px;
   }

   &--title {
      font-family: Poppins;
      font-size: 22px;
      font-weight: 600;
      line-height: 31.68px;
      color: #5770cb;
      margin-top: 4px;
      margin-bottom: 40px;
   }

   &--filters {
      margin-bottom: 40px;
      background-color: #f9faff;
      border: 1px solid #cad6ff;
      border-radius: 8px;
      padding: 20px 16px;

      & label {
         display: block;
         font-family: Poppins;
         font-size: 14px;
         font-weight: 500;
         line-height: 22px;
         color: #3a3737;
         margin-bottom: 8px;
      }
   }
}
