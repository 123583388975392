.preview-banner {
   position: relative;
   width: 100%;
   height: auto;
   max-height: 378px;
   display: flex;
   justify-content: space-between;
   background-color: #d9e3ff;
   overflow: hidden;

   flex-direction: column;

   &--info {
      z-index: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 32px;
      justify-content: center;
      padding: 40px 20px 20px 20px;

      &---status {
         height: min-content;
      }

      &---desc {
         height: 100%;

         h5 {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            line-height: 14px;
            text-align: left;
            color: #5770cb;
         }
         h2 {
            font-family: Poppins;
            font-weight: 600;
            text-align: left;
            color: #252938;

            font-size: clamp(1.5rem, 0.625vw + 1.375rem, 1.875rem);
            line-height: clamp(1.5rem, 2.188vw + 1.063rem, 2.813rem);
         }
         p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4; /* number of lines to show */
            line-clamp: 4;
            -webkit-box-orient: vertical;

            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 20.16px;
            text-align: left;
            color: #707073;
         }
         button {
            padding: 0;
            display: flex;
            align-items: center;

            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            color: #5770cb;

            &:hover {
               background: none !important;
            }
         }
      }
   }

   &--image {
      width: 218.38px;
      height: 118.79px;
      overflow: hidden;
      position: absolute;
      top: -30px;
      right: 0px;
      z-index: 0;

      img {
         width: 100%;
         height: auto;
         object-fit: cover;
         object-position: right;
      }
   }
}

@media (min-width: 768px) {
   .preview-banner {
      flex-direction: row;
      max-height: 339px;
      overflow: visible;

      &--info {
         width: 58.3%;
         padding: 40px 15px 40px min(5%, 60px);

         &---desc {
            p {
               -webkit-line-clamp: 2; /* number of lines to show */
               line-clamp: 2;
            }
         }
      }

      &--image {
         position: static;
         width: 41.7%;
         height: 100%;

         img {
            display: block;
            margin-left: auto;
            width: max-content;
            object-position: right;
         }
      }
   }
}

@media print {
   .preview-banner {
      flex-direction: row;
      max-height: fit-content;
      overflow: unset;

      &--info {
         width: 58.3%;
         padding: 40px 15px 40px min(5%, 60px);

         &---desc {
            p {
               -webkit-line-clamp: unset; /* number of lines to show */
               line-clamp: unset;
            }
         }
      }

      &--image {
         position: static;
         width: 41.7%;
         height: 100%;
      }
   }
}
