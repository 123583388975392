// .admin-workspace {
//    .top-title {
//       margin: 20px;
//       p {
//          font-style: normal;
//          font-weight: 300;
//          font-size: 20px;
//          line-height: 28px;
//          letter-spacing: -0.02em;
//          text-align: center;
//          color: var(--kaporg-black-text);
//       }
//    }

//    .box {
//       width: 100%;
//       height: 200px;
//       background: var(--kaporg-light-green);
//       color: var(--kaporg-black-text);
//       border-radius: 25px;
//       margin: 20px;
//       .invite-button{
//          cursor: pointer;
//          font-size: 12px;
//          border: 1px solid var(--kaporg-icon);
//          padding: 6px;
//          border-radius: 10px;
//          color: var(--kaporg-icon);
//    }
//    }

//    .icon {
//       color: var(--kaporg-icon);
//    }

//    .box-dot {
//       width: 100%;
//       height: 200px;
//       border: 5px dashed var(--kaporg-dark-green);
//       border-radius: 25px;
//       margin: 20px;
//       span {
//          display: flex;
//          justify-content: center;
//          margin-top: 40px;
//       }
//    }

//    .top-form {
//       .add-title {
//          font-weight: 800;
//          font-size: 20px;
//          line-height: 28px;
//          letter-spacing: -0.02em;
//          color: var(--kaporg-black-text);
//       }
//       form {
//          width: 70%;
//          margin: 0 auto;
//       }
//    }

//    .form-group {
//       .form-control {
//          border: 1px solid var(--kaporg-border-color);
//          width: 100%;
//          color: var(--kaporg-black-text);
//       }
//    }

//    .errorMessage {
//       color: red;
//       margin-top: 0.2px;
//    }
//    .PrivateTabIndicator-colorPrimary-8 {
//       background-color: green;
//    }
// }

.container {
   padding: 0;
   width: 100%;
}

.choose-new-survey-method {
   font-family: Poppins, Helvetica, "sans-serif";

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   padding: 0;
   margin: 0;
   background-color: #eef2ff;

   h3 {
      font-size: 28px;
      font-weight: 600;
   }

   .my-workspaces {
      margin-bottom: 5%;
      width: 80%;
      min-height: 50%;
      text-align: center;
      padding: 2% 5%;
      background-color: white;
      box-shadow: 0px 2px 8px 0px #e8e8e8;
      border-radius: 8px;

      .workspace-box {
         background-color: #de8344;
         width: 30%;
         min-height: 200px;
         border-radius: 8px;
      }
   }
}

.admin-members {
   .ant-table-tbody > tr > td {
      padding: 16px 10px;
      border-bottom: 1px solid var(--Primary-Main-Blue-300, #eef2ff);
   }
   .ant-table-tbody > tr > td:first-child {
      min-width: 260px;
      border-left: 1px solid var(--Primary-Main-Blue-300, #eef2ff);
   }

   .ant-table-tbody > tr > .ant-table-cell-fix-right {
      box-shadow: 0px 0 5px 0px #cad6ff; /* Adjust box shadow properties as needed */
      padding: 16px 10px;
   }
}

.workspace-dropdown {
   .ant-dropdown-menu {
      display: flex;
      flex-direction: column;
   }
}

.project-members {
   padding: 3% 8%;
   margin: 2%;
   background-color: white;
   box-shadow: 0px 2px 8px 0px #e8e8e8;
   border-radius: 8px;

   .enroll-button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: none;
      padding: 8px 10px;
      gap: 3px;
      width: 100px;
      border-radius: 8px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      gap: 5px;
   }

   .members-remove-button {
      background: #eef2ff;
      color: #5770cb;
   }

   .members-enroll-button {
      background: #5770cb;
      color: #fcfcfc;
   }

   .ant-table {
      overflow-x: auto;

      tr {
         th {
            width: 100%;
            background-color: #eef2ff !important;
            color: #5770cb;
         }

         td {
            max-height: 10px !important;
            overflow: hidden;
            text-overflow: ellipsis;
         }
      }
   }

   .ant-pagination {
      justify-content: center !important;
   }

   // Workspace Members table

   .ant-table-tbody {
      tr {
         :nth-child(3) {
            text-align: start;
         }

         .ant-table-cell {
         }
      }
   }

   .ant-table-thead > tr > td {
      background-color: #eef2ff !important;
   }

   .ant-table-tbody td {
      max-width: 200px;
      max-height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
   }

   .ant-table-tbody > tr {
      line-height: 5px;
   }

   h3 {
      color: black;
      margin-bottom: 3%;

      span {
         display: inline-flex;
         align-items: center;
         color: #a6a6a6;
         margin-left: 10px;
         gap: 4px;
      }

      @media screen and (max-width: 767px) {
         margin-bottom: 50px;
      }
   }

   // .ant-select-selector {
   //    width: 130px !important;
   // }

   .ant-select-selection-search {
      width: 140px !important;
   }

   .table-control-group {
      .table-control {
         gap: 15px;

         .ant-select-selector {
            display: flex;
            align-items: center;
            height: 40px !important;
         }

         button {
            border: none;
            height: 40px;
            width: 90px;
            background: #5770cb;
            color: white;
            border-radius: 50px;
            padding: 5px;
            transition: all 0.3s ease-in-out;

            &:hover {
               background-color: #445ebe;
            }
         }

         .filter-search-group {
            display: flex;
            gap: 15px;
         }
      }

      .new-member-button {
         border: none;
         height: 40px;
         width: 140px;
         background: #5770cb;
         color: white;
         border-radius: 8px;
         padding: 5px;
         transition: all 0.3s ease-in-out;

         margin-left: 10px;

         &:hover {
            background-color: #445ebe;
         }
      }
   }

   @media (max-width: 700px) {
      .table-control-group {
         flex-direction: column-reverse;

         .new-member-button {
            margin-bottom: 10px;
            margin-left: 0 !important;
            width: 100% !important;
         }
      }
   }

   @media (max-width: 499px) {
      .table-control {
         flex-direction: column;
      }
   }

   .filter-group {
      padding: 20px 0px;

      .filter-row {
         display: flex;
         gap: 12px;
         flex: 1 0 0;
      }

      .filter-button {
         background-color: #5770cb;
         border-radius: 28px;
         color: white;
         font-weight: 500;
         height: 40px;
         width: 70%;
      }

      @media screen and (max-width: 769px) {
         .ant-col {
            margin-right: 0;
         }

         .filter-button {
            width: 100%;
         }
      }
   }
}

.admin-new-member-page {
   .title {
      color: var(--blue-purple-dark-purple, #252938);
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      padding-left: 20px;
      margin: 20px 0 20px 0;

      @media screen and (max-width: 768px) {
         padding-left: 0;
         text-align: center;
      }
   }

   button {
      border-radius: 8px;
      background: var(--blue-purple-purple-1, #5770cb);
      /* Button drop shadow */
      box-shadow: 0px 3px 5px 0px rgba(128, 128, 128, 0.35);
   }

   .admin-new-member-section {
      padding: 3% 8%;
      margin: 2% auto;
      background-color: white;
      box-shadow: 0px 2px 8px 0px #e8e8e8;
      border-radius: 10px;
      border: 1px solid #e8e8e8;
      width: 100%;
      position: relative;
      box-shadow: 0px 4px 12px 0px #a5b1d9;

      .new-member-information {
         display: flex;
         flex-direction: column;
         align-items: center;
         width: 100%;
      }

      .role-button {
         display: flex;
         align-items: center;
         position: absolute;
         bottom: 17%;
         left: 12%;

         span {
            font-weight: 500;
            font-size: 14px;
            font-style: normal;
         }

         button {
            border-radius: 8px;
            box-shadow: 0px 1px 4px 0px #a5b1d9;
            background: #eef2ff;
            color: #5770cb;

            &:hover {
               background-color: #5770cbe9;
               color: white !important;
            }
         }

         @media screen and (max-width: 768px) {
            left: 14%;
         }

         @media screen and (max-width: 600px) {
            display: block;
            bottom: 14%;

            button {
               margin: 10px 0 0 0;
            }
         }
      }

      button {
         display: flex;
         justify-content: center;
         align-items: center;
         border: none;
         height: 40px;
         min-width: 140px;
         color: white;
         border-radius: 8px;
         padding: 5px;
         transition: all 0.3s ease-in-out;
         font-weight: 600;

         margin-left: 10px;

         &:hover {
            background-color: #394261;
            color: white !important;
         }
      }

      .new-member-submit-button-group {
         display: flex;
         justify-content: center;
         margin: 100px 0 10px 0;
         align-items: baseline;

         .cancel {
            border: 1px solid #d9d9d9;
            background-color: transparent;
            color: #707073 !important;

            &:hover {
               border-color: #252938 !important;
            }
         }
      }

      .ant-select-selection-item {
         .tooltips {
            display: none !important;
         }
      }

      label {
         font-weight: 600;
      }

      @media (max-width: 568px) {
         .ant-form-horizontal {
            width: 100% !important;
            margin: 0 auto;
         }
      }
   }
}

.admin-members .ant-table-tbody > tr > td:last-child {
   overflow: visible;
}

.deleteHeading {
   color: var(--Purple-Notifications-Purple, #8153f3);
   text-align: center;
   font-family: Poppins;
   font-size: 24px;
   font-style: normal;
   font-weight: 600;
   line-height: 24px;
   margin-bottom: 20px;
}

.paragraphText {
   color: var(--Greys-Dark-gray, #707073);
   text-align: center;
   font-family: Poppins;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   /* 125% */
   margin-bottom: 20px;
}
