.tasksSection {
   padding-bottom: 50px;

   .ant-tabs {
      margin: 0 30px;

      .ant-tabs-nav::before {
         border-bottom: 1px solid #cad6ff;
      }

      .ant-tabs-tab-active {
         .ant-tabs-tab-btn {
            color: #5770cb !important;
            font-size: 15px;
            font-family: Poppins;
         }
      }

      .ant-tabs-tab {
         .ant-tabs-tab-btn {
            color: #838383;
            font-size: 15px;
            font-family: Poppins;
         }
      }
   }

   .tasksTab {
      margin: 0;
   }
}
