.login.login-1 .login-signin,
.login.login-1 .login-signup,
.login.login-1 .login-forgot {
   display: none;
}

.login.login-1.login-signin-on .login-signup {
   display: none;
}

.login.login-1.login-signin-on .login-signin {
   display: block;
}

.login.login-1.login-signin-on .login-forgot {
   display: none;
}

.login.login-1.login-signup-on .login-signup {
   display: block;
}

.login.login-1.login-signup-on .login-signin {
   display: none;
}

.login.login-1.login-signup-on .login-forgot {
   display: none;
}

.login.login-1.login-forgot-on .login-signup {
   display: none;
}

.login.login-1.login-forgot-on .login-signin {
   display: none;
}

.login.login-1.login-forgot-on .login-forgot {
   display: block;
}

@media (max-width: 992px) {
   .login.login-1 .login-aside {
      width: 100% !important;
   }
   .login.login-1 .login-form {
      width: 100% !important;
      max-width: 500px !important;
   }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
   .login.login-1 .login-aside {
      width: 100%;
      max-width: 400px;
   }
}

@media (max-width: 991.98px) {
   .login.login-1 .login-form {
      width: 100%;
      max-width: 350px;
   }
}

@media (max-width: 575.98px) {
   .login.login-1 .login-form {
      width: 100%;
      max-width: 100%;
   }
}
/*# sourceMappingURL=login-1.css.map */

.login-layout {
   .login-signin-on {
   }
}

.layout-content {
   overflow: auto !important;
   height: 100vh;
}

/* finished */

.step-section .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
   background-color: #de8344 !important;
   border-color: #de8344 !important;
}

.step-section
   .ant-steps
   .ant-steps-item-finish
   > .ant-steps-item-container
   > .ant-steps-item-content
   > .ant-steps-item-title {
   color: white !important;
}

.step-section .ant-steps {
   color: white !important;
}

.step-section path {
   color: white;
}
/* in progress */

.step-section .ant-steps .ant-steps-item-process .ant-steps-item-icon {
   background-color: #252938 !important;
   border: 2px solid white !important;
}

.step-section
   .ant-steps
   .ant-steps-item-process
   > .ant-steps-item-container
   > .ant-steps-item-content
   > .ant-steps-item-title {
   color: white !important;
}

.step-section
   .ant-steps
   .ant-steps-item-process
   > .ant-steps-item-container
   > .ant-steps-item-content
   > .ant-steps-item-description {
   color: white !important;
}

/* waiting */

.step-section .ant-steps .ant-steps-item-wait .ant-steps-item-icon {
   background-color: #252938;
   border: 2px solid #707073 !important;
   color: #707073 !important;
   z-index: 1;
}

.step-section
   .ant-steps
   .ant-steps-item-wait
   > .ant-steps-item-container
   > .ant-steps-item-content
   > .ant-steps-item-title {
   color: #707073;
}

.step-section
   .ant-steps
   .ant-steps-item-wait
   > .ant-steps-item-container
   > .ant-steps-item-content
   > .ant-steps-item-description {
   color: #707073;
}

.step-section .ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
   color: #707073;
}

.step-section .ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
   float: left;
   margin-inline-end: 16px;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 40px;
   height: 40px;
   margin-bottom: 50px;
}

/* tail */

.ant-steps-item-tail {
   position: absolute;
   top: 0;
}

.step-section
   .ant-steps
   .ant-steps-item-finish
   > .ant-steps-item-container
   > .ant-steps-item-tail::after {
   background-color: #de8344;
}

.step-section
   .ant-steps.ant-steps-vertical
   > .ant-steps-item
   > .ant-steps-item-container
   > .ant-steps-item-tail {
   position: absolute;
   top: 0;
   inset-inline-start: 19px;
   width: 1px;
   height: 100%;
   padding: 38px 0 6px;
}
@media screen and (max-width: 540px) {
   .logo-height {
      height: 20px !important;
   }
}
