.antTableData {
   width: 100%;
   margin: 0 auto;
   overflow: auto;
   tr {
      .ant-table-selection-column {
         border-bottom: 0;
      }
   }

   .ant-table-thead {
      .ant-checkbox {
         border: 1px solid #5770cb;
      }
      .ant-table-cell {
         background: #d9e3ff;
         color: #5770cb;
      }
      .ant-checkbox-inner {
         background-color: transparent;
      }

      .ant-checkbox-indeterminate {
         .ant-checkbox-inner::after {
            background-color: #5770cb;
         }
      }

      th:nth-child(1) {
         .anticon {
            display: none;
         }
      }

      .ant-table-column-sort,
      .ant-table-column-has-sorters:hover {
         background: #d9e3ff !important;
      }
   }

   .ant-table-thead ::before {
      display: none;
   }

   .ant-table-tbody {
      color: #797979;

      td {
         font-family: "Poppins";
         font-size: 12px;
         font-weight: 500;
      }

      .ant-table-column-sort {
         background: #fff;
      }
   }

   .status,
   .collectionType {
      font-weight: 500;
      padding: 4px 12px;
      text-align: center;
      border-radius: 16px;
      font-size: 12px;
      line-height: 18px;
      margin: 0;
   }

   .assigned {
      background: #fff9e5;
      color: #fb8800;
      border: 1px solid #fff9e5;
      width: 100px;
   }

   .finished {
      background: #e2ffef;
      color: #0e9349;
      border: 1px solid #e2ffef;
      width: 100px;
   }

   .in-progress {
      background: #eef2ff;
      color: #6b89fc;
      border: 1px solid #eef2ff;
      width: 100px;
   }

   .rejected {
      background: #fce4e4;
      color: #ea5455;
      border: 1px solid #fce4e4;
      width: 100px;
   }

   // .not-started {
   //    background: #f2f2f2;
   //    color: #707073;
   //    border: 1px solid #f2f2f2;
   // }

   .collectionType {
      background: #eef2ff;
      color: #6b89fc;
      border: 1px solid #6b89fc;
      border-radius: 30px;
      width: 100px;
   }

   .enumeratorName {
      display: flex;
      gap: 8px;

      p {
         margin-bottom: 0;
         display: flex;
         align-items: center;
      }
   }

   .stickyIconsWrapper {
      display: flex;
      justify-content: space-around;
      font-size: 16px;
   }
}

.submissionsTableView {
   .antTableData {
      .ant-table-thead {
         .ant-table-cell:nth-child(2),
         .ant-table-cell:nth-child(3),
         .ant-table-cell:nth-child(4) {
            min-width: 200px;
            max-width: 200px;
            height: 50px;
         }
      }
      .ant-table-cell-fix-right {
         min-width: 80px;
         max-width: 80px;

         .stickyIconsWrapper {
            color: #5770cb;
         }
      }
   }
}

.showOptionsModal {
   .ant-modal-body {
      height: 250px;
      overflow-y: auto;
      overflow-x: hidden;
   }
}
