.geopoint-submission-type-wrapper {
   label {
      display: block;
   }

   &--input {
      width: 100%;
      border-color: #999999;
   }

   &--gps {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (min-width: 1100px) {
         flex-direction: row;
      }
      &_coords {
         // .lat {}
         // .lng {}
         flex-direction: column;

         @media (min-width: 768px) {
            flex-direction: row;
         }
      }

      &_accuracy {
         // .alt {}
         // .acc {}
         .location {
            display: flex;
            align-items: flex-end;
            gap: 5px;
         }
      }
   }

   &--error {
      display: block;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      color: #ff4d4f;
   }
}
